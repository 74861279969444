import { forwardRef } from 'react';
import { Box, TextField } from '@mui/material';
import { TitleLabel } from './Label';

const txtInputProps = {
  outer: {
    disableUnderline: true,
    style: {
      marginTop: '12px',
      borderRadius: '8px',
      height: '48px',
    },
  },
  inner: {
    style: { padding: '8px 16px', fontSize: '15px' },
  },
};

const InputLabel = forwardRef(
  ({ inputLabel, placeholder, id, background, type, value, onChange }, ref) => {
    return (
      <Box mt="16px">
        <TitleLabel fontSize="15px">{inputLabel}</TitleLabel>
        <TextField
          autoComplete="off"
          variant="filled"
          fullWidth
          id={id}
          type={type ? type : 'text'}
          placeholder={placeholder}
          InputProps={
            background
              ? {
                  ...txtInputProps.outer,
                  style: {
                    ...txtInputProps.outer.style,
                    background,
                  },
                }
              : txtInputProps.outer
          }
          inputProps={txtInputProps.inner}
          inputRef={ref}
          value={value}
          onChange={onChange && ((e) => onChange(e.target.value))}
        />
      </Box>
    );
  },
);

export default InputLabel;
