import { Box, Button, Stack } from '@mui/material';
import { styled } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BottomPanel from '../../components/BottomPanel';
import Icon from '../../components/Icon';
import { Label, TitleLabel } from '../../components/Label';
import { navigationBarHeight } from '../../components/Layout';
import NavigationBar from '../../components/NavigationBar';
import Panel from '../../components/Panel';
import StyledButton from '../../components/StyledButton';
import { UserContext } from '../../UserContext';
import { getBankBranchName, getBankName } from '../../utils/BankCode';
const sxContainer = {
  pt: `${navigationBarHeight}px`,
};

const CustomerServiceButton = styled(Button)(({ theme }) => ({
  color: '#000',
  backgroundColor: '#FFF',
  fontSize: '17px',
  fontWeight: 'bold',
  height: '48px',
  borderRadius: '9px',
  border: '1px solid #828282',
  '&:focus': {
    color: '#000',
    backgroundColor: '#FFF',
  },
}));

const ListItem = styled('li')(({ theme }) => ({
  color: '#000',
  fontSize: '15px',
  fontWeight: 'regular',
}));

export default function BankStatusPage() {
  const { user, setTabIndex, setLoading, setNavTitle } =
    useContext(UserContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const status = user?.bankAccount?.status;
  const bankCode = user?.bankAccount?.bankCode;
  const bankBranchCode = user?.bankAccount?.branchNumber;
  const [bankName, setBankName] = useState('');
  const [bankBranchName, setBankBranchName] = useState('');

  useEffect(() => {
    // console.log('Redirected from:', state?.from);
    if (status === 'succeed') {
      const getBankBranchNames = async () => {
        setLoading(true);
        const _bankName = await getBankName(bankCode);
        const _bankBranchName = await getBankBranchName(
          bankCode,
          bankBranchCode,
        );
        setBankName(_bankName);
        setBankBranchName(_bankBranchName);
        setLoading(false);
      };
      getBankBranchNames();
    }
  }, [bankCode, bankBranchCode, setLoading, status, state]);

  const handleBack = () => {
    const pathsToBankAccount = ['/member', '/asset'];
    if (!pathsToBankAccount.includes(state?.from)) {
      setTabIndex(0);
      setNavTitle('OP加密資產存摺');
      navigate('/');
    } else {
      navigate(state?.from);
    }
  };

  const handleSetBankAccount = () => {
    navigate('/bankAccount', {
      state: { from: '/bankStatus', originFrom: state?.from },
    });
  };

  const handleCustomerService = () => {
    window.location.href = `mailto:${process.env.REACT_APP_CS_EMAIL}`;
  };

  const handleComplete = () => {
    // 需要盤一下，這一頁回上一頁的邏輯
    // if (backNavigation) {
    //     navigate(backNavigation);
    // } else {
    //     navigate(-1);
    // }
    setTabIndex(0);
    setNavTitle('OP加密資產存摺');
    navigate('/');
  };

  const navigation = () => {
    const titles = {
      verifing: '綁定銀行帳號',
      succeed: '我的銀行帳號',
      failed: '銀行帳號',
    };

    return (
      <NavigationBar
        title={titles[status]}
        leftButtonHandler={handleBack}
        hideLeftButton={status === 'verifing'}
      />
    );
  };

  const bankAccountVerifying = () => {
    return (
      <Stack
        sx={{ mt: '150px' }}
        spacing={2}
        direction={'column'}
        alignItems="center">
        <Box>
          <Icon src="img_processing.png" width={122} height={122} />
        </Box>
        <Box>
          <TitleLabel>銀行帳號綁定審核中</TitleLabel>
        </Box>
        <Box width={'100%'} marginTop="10px" marginBottom="37px">
          <Label fontSize="15px" align="center">
            驗證審核約需3個工作天，請您耐心等待，審核結果我們將會透過信件通知您，謝謝。
          </Label>
        </Box>
      </Stack>
    );
  };

  const bankAccountVerifyFail = () => {
    return (
      <Stack
        sx={{ mt: '40px' }}
        spacing={2}
        direction={'column'}
        alignItems="center">
        <Box>
          <Icon src="img_fail.png" width={122} height={122} />
        </Box>

        <Box>
          <TitleLabel>銀行帳號驗證失敗</TitleLabel>
        </Box>

        <Box width={'100%'} marginTop="10px" marginBottom="37px">
          <Label fontSize="15px" align="center">
            您好，因您提供的銀行帳號驗證失敗，請確認為本人使用的帳戶且帳戶資訊正確並重新提交，若有任何問題，請聯繫我們的客服人員，謝謝
          </Label>
        </Box>

        <Box />

        <Box width={'100%'} marginTop="10px">
          <StyledButton
            variant="contained"
            fullWidth
            disableElevation
            sx={{ height: '50px' }}
            onClick={handleSetBankAccount}>
            重新提交帳號
          </StyledButton>

          <CustomerServiceButton
            variant="contained"
            fullWidth
            disableElevation
            sx={{ height: '50px', marginTop: '20px' }}
            onClick={handleCustomerService}>
            聯繫客服
          </CustomerServiceButton>
        </Box>
      </Stack>
    );
  };

  const bankAccountVerifySuccess = () => {
    const items = [
      '用戶僅能用綁定的銀行帳號進行入金和提領的操作',
      '用戶同一時間僅能綁定一個銀行帳號',
      '若你欲更改已綁定的帳號，請聯繫客服申請換綁',
    ];

    const bankAccount = user?.bankAccount?.accountNumber;
    return (
      <>
        <Panel>
          <Box padding="20px">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              // height='72px'
            >
              <Label fontSize="17px" fontWeight="bold">
                {bankName}
              </Label>

              <Label fontSize="15px" fontWeight="bold">
                已綁定
              </Label>
            </Box>

            <Label
              fontSize="13px"
              fontWeight="regular"
              sx={{ marginTop: '8px' }}>
              {bankBranchName}
            </Label>

            <Label fontSize="24px" fontWeight="bold" sx={{ marginTop: '24px' }}>
              {bankAccount}
            </Label>
          </Box>
        </Panel>

        <Box
          sx={{
            marginLeft: '-16px',
            marginRight: '8px',
            marginTop: '24px',
          }}>
          <ul>
            {items.map((item, index) => (
              <ListItem key={index}>{item}</ListItem>
            ))}
          </ul>
        </Box>
      </>
    );
  };

  const renderByStatus = (status) => {
    switch (status) {
      case 'succeed':
        return bankAccountVerifySuccess();
      case 'failed':
        return bankAccountVerifyFail();
      case 'verifing':
        return bankAccountVerifying();
      default:
        return;
    }
  };

  const bottomPanel = () => {
    return (
      <BottomPanel>
        <StyledButton
          variant="contained"
          fullWidth
          disableElevation
          onClick={handleComplete}>
          返回
        </StyledButton>
      </BottomPanel>
    );
  };

  return (
    <Box sx={sxContainer}>
      {navigation() /* navigation bar */}

      <Box padding="24px">{renderByStatus(status)}</Box>

      {status === 'verifing' && bottomPanel()}
    </Box>
  );
}
