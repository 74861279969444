import { Box, Button, Stack, TextField } from "@mui/material";
import { styled } from "@mui/system";
import BottomPanel from "../../components/BottomPanel";
import { navigationBarHeight } from "../../components/Layout";
import NavigationBar from "../../components/NavigationBar";
import { UserContext } from "../../UserContext";
import { useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../components/Icon";
import DescriptionLabel from "./components/DescriptionLabel";
import Subtitle from "./components/Subtitle";
import secureLocalStorage from "react-secure-storage";
import Dialog from "../../components/Dialog";
import { UserAPI } from "../../apis/UserAPI";

const TradeButton = styled(Button)(({ theme }) => ({
    color: "#FFF",
    fontSize: "17px",
    fontWeight: "bold",
    borderRadius: "9px",
}));

export default function ChangePhoneNumber() {
    const navigate = useNavigate();
    const phoneNumber = secureLocalStorage.getItem("phoneNumber");
    const { setLoading } = useContext(UserContext);
    const alertMessage = useRef("");
    const [showAlert, setShowAlert] = useState(false);

    const handleSendVerifyCode = async () => {
        setShowAlert(false);
        setLoading(true);
        const response = await UserAPI.resendPhoneVerifyCode();
        setLoading(false);
        if (response?.error) {
            alertMessage.current = response?.error.message;
            setShowAlert(true);
        } else {
            navigate("/phoneVerify", {
                state: { entry: "/changePhoneNumber" },
            });
        }
    };

    return (
        <Box sx={{ pt: `${navigationBarHeight}px` }}>
            <NavigationBar
                title='偵測到新手機門號'
                hideLeftButton={true}
            />
            <Stack
                spacing={0}
                direction='column'
                alignItems='center'
                marginTop='40px'
            >
                <Icon
                    src='img_new_phone.png'
                    width={122}
                    height={120}
                />
                <DescriptionLabel
                    width='85%'
                    marginTop='40px'
                >
                    感謝您使用OP加密資產存摺，我們偵測到手機門號已變更為
                    {phoneNumber}
                    ，請重新進行驗證，謝謝
                </DescriptionLabel>
                <Box
                    display='flex'
                    width='85%'
                    marginTop='20px'
                    justifyContent='flex-start'
                >
                    <Subtitle>新手機號碼</Subtitle>
                </Box>
                <Box
                    marginTop='10px'
                    width='85%'
                >
                    <TextField
                        fullWidth
                        disabled
                        id='phone-number'
                        label=''
                        variant='outlined'
                        size='small'
                        sx={{ backgroundColor: "#F2F2F2" }}
                        value={phoneNumber}
                    />
                </Box>
            </Stack>

            <Dialog
                showDialog={showAlert}
                setShowDialog={setShowAlert}
                title='寄送失敗'
                message={alertMessage.current}
                actionLabel='確定'
                actionHandler={() => setShowAlert(false)}
            />
            <BottomPanel>
                <TradeButton
                    variant='contained'
                    fullWidth
                    disableElevation
                    onClick={handleSendVerifyCode}
                >
                    傳送驗證碼
                </TradeButton>
            </BottomPanel>
        </Box>
    );
}
