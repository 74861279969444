import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Stack, Button } from '@mui/material';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import { Label } from '../../components/Label';
import Panel from '../../components/Panel';
import BottomPanel from '../../components/BottomPanel';
import { styled } from '@mui/system';
import disclaimer from './disclaimer';
import Section from './component/Section';

const sxContainer = {
  pt: `${navigationBarHeight}px`,
};

const TradeButton = styled(Button)(({ theme }) => ({
  color: '#FFF',
  fontSize: '17px',
  fontWeight: 'bold',
  borderRadius: '9px',
}));

export default function DisclaimerPage() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const response = disclaimer;

  const handleBack = () => {
    if (state) {
      if (state.previousPage === '/signUp')
        navigate('/signUp', { state: { previousPage: '/disclaimer' } });
    } else navigate(-1);
  };

  const navigation = () => {
    return <NavigationBar title="免責聲明" leftButtonHandler={handleBack} />;
  };

  return (
    <Box sx={sxContainer}>
      {navigation() /* navigation bar */}
      <Box p="20px 16px">
        <Panel>
          <Stack spacing="12px" p="16px">
            <Label>請詳細閱讀以下條款</Label>
            {response.sections.map((section, index) => (
              <Section key={index} section={section} />  
            ))}
          </Stack>
        </Panel>
      </Box>

      <BottomPanel>
        <TradeButton
          onClick={handleBack}
          variant="contained"
          fullWidth
          disableElevation
          type="submit">
          確認
        </TradeButton>
      </BottomPanel>
    </Box>
  );
}
