import React, { useState, useEffect, useRef } from 'react';
import { Box, Fab } from '@mui/material';

const sxButtonSelected = {
  width: '60px',
  fontSize: '15px',
  fontWeight: 'medium',
  color: '#000',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.075)',
};

const sxButtonUnselected = {
  width: '60px',
  fontSize: '15px',
  fontWeight: 'medium',
  color: '#787878',
  backgroundColor: '#FFF',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0)',
};

export default function GroupButtons({
  defaultSelection,
  items,
  selectionHandler,
}) {
  const [selection, setSelection] = useState(0);
  const [selected, setSelected] = useState(true);
  const initComponent = useRef(false);

  useEffect(() => {
    if (initComponent.current === false) {
      initComponent.current = true;
      setSelection(defaultSelection);
      selectionHandler(defaultSelection);
    }
  }, []);

  const handleClick = (index) => {
    setSelected(true);
    setSelection(index);
    selectionHandler(index);
  };

  return (
    <Box display="flex" justifyContent="space-between" sx={{ m: '13px' }}>
      {items.map((item, index) => (
        <Fab
          sx={
            index === selection && selected
              ? sxButtonSelected
              : sxButtonUnselected
          }
          key={index}
          variant="extended"
          size="small"
          onClick={() => handleClick(index)}>
          {item}
        </Fab>
      ))}
    </Box>
  );
}
