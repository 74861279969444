const isEmail = (email, errors) => {
  let result = true;

  if (!email) {
    errors.email = '請輸入電子信箱 Email';
    result = false;
  } else {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    result = re.test(String(email).toLowerCase());
    if (!result) errors.email = '無效的 Email 格式';
  }
  return result;
};

const isPhoneNumber = (phoneNumber, errors) => {
  let result = true;

  if (!phoneNumber) {
    errors.phone = '請輸入會員帳號(手機號碼)';
    result = false;
  } else {
    const re = /^(09\d{2}(\d{6}|-\d{3}-\d{3}))$/;
    result = re.test(String(phoneNumber));
    if (!result) errors.phone = '無效的手機號碼格式 (09開頭10碼數字)';
  }
  return result;
};

const isPassword = (password, errors) => {
  let result = true;

  if (!password) {
    errors.password = '請輸入密碼';
    result = false;
  } else {
    const re = /^(?=.*[A-Za-z])(?=.*\d).{8,25}$/;
    result = re.test(String(password));
    if (!result)
      errors.password =
        '無效的密碼格式 (密碼需由至少八個且包含英數字的字元組成)';
  }
  return result;
};

const isPasswordConfirm = (password, passwordConfirm, errors) => {
  let result = true;
  console.log(password, passwordConfirm);
  if (!passwordConfirm) {
    errors.passwordConfirm = '請再次輸入密碼';
    result = false;
  } else {
    result = passwordConfirm === password;
    if (!result) errors.passwordConfirm = '請確認是否輸入相同密碼';
  }
  return result;
};

const isChinese = (name, errors) => {
  let result = true;

  if (!name) {
    errors.name = '請輸入會員名稱';
    result = false;
  } else {
    const re = /^[\u4E00-\u9FA5]+$/;
    result = re.test(String(name));
    if (!result) errors.name = '請輸入中文姓名';
  }
  return result;
};

const isPhoneBarcode = (barcode, errors) => {
  let result = true;

  if (!barcode) {
    errors.barcode = '請輸入手機條碼載具';
    result = false;
  } else {
    const re = /^[/]{1}[0-9A-Z+-.]{7}$/;
    result = re.test(String(barcode));
    if (!result) errors.barcode = '請輸入正確手機條碼載具';
  }
  return result;
};

const isCitizenDigital = (citizenDigital, errors) => {
  let result = true;

  if (!citizenDigital) {
    errors.citizenDigital = '請輸入自然人憑證條碼';
    result = false;
  } else {
    const re = /^[A-Z]{2}\d{14}$/;
    result = re.test(String(citizenDigital));
    if (!result) errors.citizenDigital = '請輸入正確自然人憑證條碼';
  }
  return result;
};

const isUserId = (id, errors) => {
  let result = true;

  if (!id) {
    errors.id = '請輸入身分證號碼';
    result = false;
  } else {
    const re = /^[A-Z][12]\d{8}$/;
    result = re.test(String(id));

    if (!result) {
      errors.id = '無效的身分證號碼';
      return result;
    }

    let conver = 'ABCDEFGHJKLMNPQRSTUVXYWZIO';
    let weights = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1];

    id = String(conver.indexOf(id[0]) + 10) + String(id).slice(1);

    let checkSum = 0;
    for (let i = 0; i < String(id).length; i++) {
      let c = parseInt(id[i]);
      let w = weights[i];
      checkSum += c * w;
    }

    result = checkSum % 10 === 0;
    if (!result) errors.id = '無效的身分證號碼';
  }
  return result;
};

const isLastUserId = (id, size, errors) => {
  let result = true;

  if (!id) {
    errors.id = '請輸入身分證號碼';
    result = false;
  } else {
    const re = new RegExp('^\\d{' + size + '}$');
    result = re.test(String(id));

    if (!result) {
      errors.id = '無效的身分證號碼';
      return result;
    }
  }
  return result;
};

const validator = {
  isEmail,
  isPhoneNumber,
  isChinese,
  isUserId,
  isLastUserId,
  isPhoneBarcode,
  isCitizenDigital,
  isPassword,
  isPasswordConfirm,
};

export default validator;
