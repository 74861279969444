import React from 'react';
import { Box } from '@mui/material';
import Icon from '../Icon';
import { TitleLabel } from '../Label';
import Dialog from '../Dialog';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';

const menuItems = [
  {
    label: '所有幣種',
    icon: '/images/AllCurrencies.png',
    filter: 'ALL',
  },
  {
    label: 'BTC',
    icon: '/images/BTC.png',
    filter: 'BTC',
  },
  {
    label: 'ETH',
    icon: '/images/ETH.png',
    filter: 'ETH',
  },
  // 初期USDC不上線
  // {
  //   label: 'USDC',
  //   icon: '/images/USDC.png',
  //   filter: 'USDC',
  // },
];

export default function CurrencyMenu({
  showMenu,
  setShowMenu,
  filter,
  setFilter,
  originFrom,
}) {
  const navigate = useNavigate();
  const handleSelectMenuItem = (menuItem) => {
    navigate(`/assetHistory/${menuItem.filter}`, {
      state: { originFrom: originFrom },
    });
    setFilter(menuItem.filter);

    setTimeout(function () {
      setShowMenu(false);
    }, 150);
  };

  return (
    <Dialog showDialog={showMenu} setShowDialog={setShowMenu} title="選擇幣種">
      {menuItems.map((menuItem, index) => (
        <Box
          key={index}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginLeft="8px"
          marginRight="8px"
          onClick={() => handleSelectMenuItem(menuItem)}>
          <Box height="54px" display="flex" alignItems="center">
            <Icon width="30px" height="30px" src={menuItem.icon} />

            <TitleLabel fontSize="15px" fontWeight="medium" marginLeft="16px">
              {menuItem.label}
            </TitleLabel>
          </Box>

          {filter === menuItem.filter && <CheckIcon />}
        </Box>
      ))}
    </Dialog>
  );
}
