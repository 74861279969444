import { useEffect } from 'react';

export default function useImageUploader(uploadedImages, setUploadedImages) {
  //upload
  const handleImageUpload = (newImages) => {
    setUploadedImages((prevImages) => [...prevImages, ...newImages]);
  };
  //delete
  const handleImageDelete = (id) => {
    setUploadedImages((prevImages) => {
      //clean up
      prevImages.forEach((image) => {
        if (image.id === id) {
          URL.revokeObjectURL(image.url);
        }
      });
      //update
      const updateImages = prevImages.filter((image) => image.id !== id);
      return updateImages;
    });
  };

  // 使用 cleanup function，透過呼叫這個方法來讓瀏覽器知道不用在記憶體中繼續保留 URL.createObjectURL() 創建的 URL 物件
  /*
  useEffect(() => {
    return () => {
      uploadedImages.forEach((image) => URL.revokeObjectURL(image.url));
    };
  }, [uploadedImages]); //依賴項變化時執行副作用
  */
  return {
    handleImageUpload,
    handleImageDelete,
  };
}
