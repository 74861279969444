import {
  Stack,
  Paper,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const ItemBox = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'start',
  color: theme.palette.text.primary,
  height: 42,
  lineHeight: '42px',
  paddingLeft: '16px',
  border: '1px solid #CFCFCF',
  borderRadius: '8px',
  boxShadow: '0px 3px 3px 0px rgba(0, 0, 0, 0.05)',
}));

export default function CheckboxGroup({ title, options, name, selectedValue, showError, onChange }) {
  selectedValue = Array.isArray(selectedValue) ? selectedValue : [];

  const isChecked = (value) => selectedValue.some(opt => opt.value === value);
  const getRemark = (value) => selectedValue.find(opt => opt.value === value)?.remark || '';

  const handleChange = (event) => {
    const { value, checked } = event.target;
    let updateSelectedValues = [...selectedValue];
    if (checked) {
      updateSelectedValues.push({ value, remark: '' });  
    } else {
      updateSelectedValues = updateSelectedValues.filter(option => option.value !== value);  
    }
    onChange(updateSelectedValues);
  };

  const handleRemarkChange = (value, remark) => {
    const updateSelectedValues = selectedValue.map(option =>
      option.value === value ? { ...option, remark } : option
    );
    onChange(updateSelectedValues);
  };

  return (
    <FormControl error={showError}>
      <FormGroup>
        <Stack spacing='16px'>
          {options.map((item) => (
            <Stack key={item.value}>
              <ItemBox>
                <FormControlLabel 
                  control={
                    <Checkbox 
                      checked={isChecked(item.value)}
                      onChange={handleChange} 
                      name={name} 
                      value={item.value} 
                    />}
                  label={item.label} 
                />
              </ItemBox>
              {selectedValue.some(opt => opt.value === item.value && item.textbox) && (
                <TextField 
                  variant="standard" 
                  style={{marginTop:'8px'}}
                  label={item.annotation} 
                  value={getRemark(item.value)}
                  onChange={(e) => handleRemarkChange(item.value, e.target.value)}
                />
              )}
            </Stack>
          ))}
        </Stack>
      </FormGroup>
      <FormHelperText id={`${name}-error-text`}>{showError && `請填寫: ${title}`}</FormHelperText>
    </FormControl>
  );
}
