import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CoinNumberFormat } from '../../utils/CoinNumberFormat';
import moment from 'moment/moment';
import TradeDetailPanel from '../../components/asset/TradeDetailPanel';
import { navigationBarHeight } from '../../components/Layout';
import { Box } from '@mui/material';
import CopyToClipboardButton from '../../components/CopyToClipboardButton';
import { TitleLabel } from '../../components/Label';

const sxContainer = {
  pt: `${navigationBarHeight}px`,
  pl: '16px',
  pr: '16px',
};

const depositMethods = {
  bank: '銀行轉帳',
  store: '門市儲值',
  order: '訂單儲值',
};

const formatUnixTimestamp = (timestamp) => {
  return moment.unix(timestamp).format('YYYY/MM/DD HH:mm');
};

export default function DepositDonePage() {
  const { state, pathname } = useLocation();
  const tradeType = pathname.split('/')[1];
  const { title, depositRecord } = state;
  const createdTime = depositRecord?.createdTime;
  const amount = depositRecord?.amount;
  const txId = depositRecord?.txId;
  const depositMethod = depositRecord?.depositMethod;
  const [openCopyMessage, setOpenCopyMessage] = useState(false);

  const handleClickCopy = () => {
    navigator.clipboard.writeText(txId);
    setOpenCopyMessage((prev) => !prev);
  };

  const abbrOrderId = (id) => {
    return id.slice(0, 10) + '...';
  };

  const depositRecords = [
    {
      title: '儲值紀錄號碼',
      content: (
        <Box display="flex" alignItems="center">
          <TitleLabel fontSize="15px">{abbrOrderId(txId)}</TitleLabel>
          <CopyToClipboardButton
            openCopyMessage={openCopyMessage}
            onClick={handleClickCopy}
            message="儲值紀錄號碼已複製"
          />
        </Box>
      ),
    },
    {
      title: '日期',
      content: formatUnixTimestamp(createdTime),
    },
    {
      title: '儲值方式',
      content: depositMethods[depositMethod],
      fontWeight: 'bold',
    },
    {
      title: '儲值金額',
      content: (
        <>
          <CoinNumberFormat value={amount} /> TWD
        </>
      ),
    },
    {
      title: '狀態',
      content: '已完成',
    },
  ];

  return (
    <Box sx={sxContainer}>
      <TradeDetailPanel
        title={title}
        records={depositRecords}
        tradeType={tradeType}
      />
    </Box>
  );
}
