import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import MemberCenterCell from "../../components/member/MemberCenterCell";
import Separator from "../../components/Separator";
import NavigationBar from "../../components/NavigationBar";
import { navigationBarHeight } from "../../components/Layout";
import { TitleLabel } from "../../components/Label";
import { Box } from "@mui/material";
import Panel from "../../components/Panel";
import { useEffectOnce } from "../../utils/UseEffectOnce";
import { UserAPI } from "../../apis/UserAPI";
import { notifyOpenBrower } from "../../utils/WebViewCallback";

export default function InvoiceSettingPage() {
    const billInfoDataRef = useRef(null);
    const navigate = useNavigate();

    useEffectOnce(() => {
        async function fetchBillInfoData() {
            const userData = await UserAPI.getBillInfo();
            billInfoDataRef.current = userData;
        }

        fetchBillInfoData();
    }, []);

    const sxContainer = {
        pt: `${navigationBarHeight}px`,
    };

    async function getEipUrl() {
        const response = await UserAPI.getEipUrl();
        notifyOpenBrower(response.redirectUrl);
    }

    const handleChangePhoneBarcodePage = () => {
        navigate("/changePhoneBarcode", {
            state: { billInfo: billInfoDataRef.current },
        });
    };

    const handleChangeCitizenDigitalPage = () => {
        navigate("/changeCitizenDigital", {
            state: { billInfo: billInfoDataRef.current },
        });
    };

    const handleChangeDoneeCodePage = () => {
        navigate("/changeDoneeCode", {
            state: { billInfo: billInfoDataRef.current },
        });
    };

    const handleCarrierBindPage = () => {
        getEipUrl();
    };

    const handleBack = () => {
        navigate(-1);
    };

    const navigation = () => {
        return (
            <NavigationBar
                title='發票載具設定'
                leftButtonHandler={handleBack}
            />
        );
    };

    var menuItems = [
        {
            text: "OP加密資產存摺會員載具歸戶",
            handler: handleCarrierBindPage,
        },
        {
            text: "手機條碼載具",
            handler: handleChangePhoneBarcodePage,
        },
        {
            text: "自然人憑證條碼載具",
            handler: handleChangeCitizenDigitalPage,
        },

        {
            text: "發票捐贈愛心碼",
            handler: handleChangeDoneeCodePage,
        },
    ];

    const memberMenu = () => {
        return (
            <>
                {menuItems.map((item, index) => (
                    <Box key={index}>
                        <MemberCenterCell item={item} />
                        {index < menuItems.length - 1 && <Separator />}
                    </Box>
                ))}
            </>
        );
    };

    return (
        <>
            <Box sx={sxContainer}>
                {navigation() /* navigation bar */}
                <Box margin='16px'>
                    <Box marginBottom='16px'>
                        <TitleLabel fontSize='18px'>電子發票載具</TitleLabel>
                    </Box>
                    <Panel>{memberMenu()}</Panel>
                </Box>
            </Box>
        </>
    );
}
