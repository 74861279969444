import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box } from '@mui/material';

export default function FileUploadBox({ content }) {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 42,
        lineHeight: '42px',
        border: '1px solid #CFCFCF',
        borderRadius: '8px',
        boxShadow: '0px 3px 3px 0px rgba(0, 0, 0, 0.05)',
        cursor: 'pointer',
      }}>
      <UploadFileIcon />
      {content}
    </Box>
  );
}
