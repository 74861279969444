import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import MemberCenterCell from '../../components/member/MemberCenterCell';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import QuizIcon from '@mui/icons-material/Quiz';
import { TitleLabel, SubTitleLabel } from '../../components/Label';
import Separator from '../../components/Separator';
import { UserContext } from '../../UserContext';
import Dialog from '../../components/Dialog';
import Panel from '../../components/Panel';

const iconSize = '28px';

const userStatus = {
  level: {
    level0: 'LEVEL 0',
    level1: 'LEVEL 1',
    level2: 'LEVEL 2',
  },
  bankAccount: {
    undefined: '未綁定',
    unverified: '未綁定',
    verifing: '綁定審核中',
    succeed: '綁定完成',
    failed: '綁定失敗',
  },
};

export default function MemberPage() {
  const { user, setUser, setNavTitle, setTabIndex } = useContext(UserContext);
  const navigate = useNavigate();
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);

  useEffect(() => {
    setTabIndex(2);
  }, [setTabIndex]);

  const handleLogout = () => {
    setUser(null);
    setNavTitle('OP加密資產存摺');
    setTabIndex(0);
    navigate('/landing');
  };

  const handleChangeAccountInfo = () => {
    navigate('/changeAccountInfo');
  };

  const handleMenuBankAccount = () => {
    if (
      !user?.bankAccount ||
      user?.bankAccount.status === null ||
      user?.bankAccount.status === 'unverified' ||
      user?.bankAccount.status === 'undefined'
    ) {
      navigate('/bankAccount', { state: { from: '/member' } });
    } else {
      navigate('/bankStatus', { state: { from: '/member' } });
    }
  };

  const handleChangeUserInvoice = () => {
    navigate('/changeInvoice', {
      state: {
        navTitle: '發票載具設定',
      },
    });
  };

  const handleFAQPage = () => {
    navigate('/faq');
  };

  const handleCustomerService = () => {
    window.location.href = `mailto:${process.env.REACT_APP_CS_EMAIL}`;
  };

  const handleUserVerify = () => {
    let level;

    if (
      user?.kycStatus === 'succeed' &&
      user?.amlStatus === 'succeed' &&
      user?.bankAccount?.status === 'succeed'
    ) {
      level = userStatus.level.level2;
    } else if (
      (user?.kycStatus === 'succeed' && user?.amlStatus === 'succeed') ||
      user?.bankAccount?.status === 'succeed'
    ) {
      level = userStatus.level.level1;
    } else {
      level = userStatus.level.level0;
    }
    return (
      <TitleLabel fontSize="15px" fontWeight="regular" color="primary.main">
        {level}
      </TitleLabel>
    );
  };

  const menuItems = [
    {
      text: '基本資料',
      icon: <AccountBoxIcon style={{ fontSize: iconSize }} />,
      handler: handleChangeAccountInfo,
    },
    {
      text: '銀行帳戶',
      icon: <AccountBalanceIcon style={{ fontSize: iconSize }} />,
      state: userStatus.bankAccount[user?.bankAccount?.status],
      handler: handleMenuBankAccount,
    },
    {
      text: '發票載具設定',
      icon: <SettingsIcon style={{ fontSize: iconSize }} />,
      handler: handleChangeUserInvoice,
    },
    {
      text: '常見問題(FAQ)',
      icon: <QuizIcon style={{ fontSize: iconSize }} />,
      handler: handleFAQPage,
    },
    {
      text: '客戶服務',
      icon: <SupportAgentIcon style={{ fontSize: iconSize }} />,
      handler: handleCustomerService,
      hideIndicator: true,
    },
    {
      text: '登出',
      icon: <LogoutIcon style={{ fontSize: iconSize }} />,
      handler: () => setShowLogoutDialog(true),
      hideIndicator: true,
    },
  ];

  const memberInfo = () => {
    return (
      <Box
        height="80px"
        display="flex"
        alignItems="center"
        justifyContent="space-between">
        <Box>
          {/* mobile number */}
          <TitleLabel fontSize="17px" fontWeight="regular">
            {user?.phoneNumber}
          </TitleLabel>
          {/* user ID */}
          <SubTitleLabel fontSize="13px" fontWeight="regular">
            UID: {('00000' + user?.id).slice(-6)}
          </SubTitleLabel>
        </Box>
        <Panel sx={{ borderRadius: '12px' }}>
          <Box padding="12px">{handleUserVerify()}</Box>
        </Panel>
      </Box>
    );
  };

  const memberMenu = () => {
    return (
      <>
        {menuItems.map((item, index) => (
          <Box key={index}>
            <MemberCenterCell item={item} />
            {index < menuItems.length - 1 && <Separator />}
          </Box>
        ))}
      </>
    );
  };

  return (
    <>
      <Box padding="16px">
        {memberInfo()}
        {memberMenu()}
      </Box>
      <Dialog
        showDialog={showLogoutDialog}
        setShowDialog={setShowLogoutDialog}
        title="登出OP加密資產存摺"
        message="請確認是否要離開OP加密資產存摺?"
        actionLabel="離開"
        actionHandler={handleLogout}
        secondaryActionLabel="繼續使用"
        secondaryActionHandler={() => setShowLogoutDialog(false)}
      />
    </>
  );
}
