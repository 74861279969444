import React from 'react';
import { Paper, Typography, IconButton, Box } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { navigationBarHeight } from './Layout';
import Icon from './Icon';
import { useTheme } from '@mui/material/styles';

export default function NavigationBar({
  title,
  titleIcon,
  leftButtonIcon,
  leftButtonHandler,
  hideLeftButton,
  children,
  noShadow,
}) {
  const theme = useTheme();
  const {
    typography: { fontWeightBold },
    palette: {
      grey,
      common: { black },
    },
    zIndex: { drawer },
  } = theme;

  const sxNavigationBar = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: `${navigationBarHeight}px`,
    zIndex: drawer,
  };

  const sxNavigationBarShadow = {
    position: 'absolute',
    width: '100%',
    height: '0.5px',
    bottom: 0,
    bgcolor: grey[300],
  };

  return (
    <Paper sx={sxNavigationBar} elevation={0}>
      {/* navigation title */}
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        position="absolute"
        alignItems="center"
        justifyContent="center"
        sx={{ height: `${navigationBarHeight}px` }}>
        {titleIcon && (
          <Icon src={titleIcon} width="25px" sx={{ marginRight: '10px' }} />
        )}

        <Typography
          textAlign="center"
          variant="h6"
          color="text"
          sx={{
            fontSize: 17,
            fontWeight: fontWeightBold,
          }}>
          {title}
        </Typography>
      </Box>

      {/* navigation items */}
      <Box
        display="flex"
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
        marginLeft="8px"
        marginRight="8px"
        height="100%">
        {/* left navigation bar item */}
        {!hideLeftButton && (
          <IconButton onClick={leftButtonHandler}>
            {leftButtonIcon ? (
              leftButtonIcon
            ) : (
              <ArrowBackIosNewIcon
                style={{ fontSize: 25 }}
                sx={{
                  color: black,
                }}
              />
            )}
          </IconButton>
        )}

        {/* right navigation bar item */}
        {children}
      </Box>

      {/* navigation bar shadow */}
      {!noShadow && <Box sx={sxNavigationBarShadow} />}
    </Paper>
  );
}
