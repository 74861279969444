import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import { Tooltip, Typography } from '@mui/material';

const SelectBox = styled(Select)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'start',
  color: theme.palette.text.primary,
  height: 42,
  lineHeight: '42px',
  paddingLeft: '16px',
  border: '1px solid #CFCFCF',
  borderRadius: '8px',
  boxShadow: '0px 3px 3px 0px rgba(0, 0, 0, 0.05)',
}));

export default function Dropdown({
  name,
  options,
  value,
  onChange,
  defaultLabel,
  isDisabled,
}) {
  return (
    <FormControl sx={{ minWidth: 120 }}>
      <SelectBox
        displayEmpty
        name={name}
        inputProps={{ 'aria-label': 'Without label' }}
        value={value}
        onChange={onChange}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em style={{ fontStyle: 'normal' }}>{defaultLabel}</em>;
          }

          const item = options.find(({ value: v }) => v === selected);
          return item.label;
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        disabled={isDisabled}>
        {options.map((option) => {
          return (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{ whiteSpace: 'normal' }}>
              {option.label}
            </MenuItem>
          );
        })}
      </SelectBox>
    </FormControl>
  );
}
