const callNative = (message) => {
  if (window.AppFunc) {
    window.AppFunc.postMessage(message);
  } else if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.AppFunc
  ) {
    window.webkit.messageHandlers.AppFunc.postMessage(message);
  } else {
    // No Android or iOS interface found
    console.log('No native APIs found.');
    return { media: 'browser' };
  }
};

const notifyLoginSuccess = () => {
  callNative('csapp=OpcryptoexLoginSuccess');
};

const notifyLoginFail = () => {
  callNative('csapp=OpcryptoexLoginFail');
};

const notifyQuitApp = () => {
  callNative('csapp=OpcryptoexQuit');
};

const notifyOpenBrower = (url) => {
  const result = callNative(url);
  if (result.media === 'browser') window.open(url, '_blank');
};

export { notifyLoginSuccess, notifyLoginFail, notifyQuitApp, notifyOpenBrower };
