import { React, useRef, useState, useContext } from "react";
import { styled } from "@mui/system";
import { useNavigate, useLocation } from "react-router-dom";
import { Typography, Box, TextField } from "@mui/material";
import NavigationBar from "../../components/NavigationBar";
import { navigationBarHeight } from "../../components/Layout";
import BottomPanel from "../../components/BottomPanel";
import validator from "./validator";
import Dialog from "../../components/Dialog";
import { UserContext } from "../../UserContext";
import { UserAPI } from "../../apis/UserAPI";
import StyledButton from "../../components/StyledButton";

const sxContainer = {
    pt: `${navigationBarHeight}px`,
};

const DescriptionLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.main,
    fontSize: "15px",
    fontWeight: "400",
}));

export default function ChangeEmailPage() {
    const navigate = useNavigate();
    const { setLoading, setUser } = useContext(UserContext);
    const inputUserEmail = useRef();
    const [errors] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const alertTitle = useRef("");
    const alertMessage = useRef("");
    const { state } = useLocation();
    const navTitle = state?.navTitle;
    const nextNavigation = state?.nextNavigation;

    const handleBack = () => {
        navigate(-1);
    };

    const checkEmail = () => {
        let alert = null;
        if (!validator.isEmail(inputUserEmail.current.value, errors)) {
            alert = errors.email;
        } else if (inputUserEmail.current.value === "") {
            alert = "請填寫Email";
        }

        if (alert) {
            alertTitle.current = "輸入錯誤";
            alertMessage.current = alert;
            setShowAlert(true);
            return false;
        } else {
            return true;
        }
    };

    const handleResendVerifyCode = async () => {
        if (!checkEmail()) {
            return;
        }

        setLoading(true);

        const userData = await UserAPI.updateEmail({
            email: inputUserEmail.current.value,
        });
        setLoading(false);

        if (userData.error) {
            alertTitle.current = "發生錯誤";
            alertMessage.current = userData.error.message;
            setShowAlert(true);
        } else {
            setUser(userData);
            if (nextNavigation) {
                navigate(nextNavigation);
            } else {
                navigate(-1);
            }
        }
    };

    const navigation = () => {
        return (
            <NavigationBar
                title={navTitle ? navTitle : "更換電子信箱地址"}
                leftButtonHandler={handleBack}
            />
        );
    };

    const bottomPanel = () => {
        return (
            <BottomPanel>
                <StyledButton
                    variant='contained'
                    fullWidth
                    disableElevation
                    onClick={handleResendVerifyCode}
                >
                    確認並發送驗證信
                </StyledButton>
            </BottomPanel>
        );
    };

    const description = () => {
        return (
            <Box
                marginTop='10px'
                marginBottom='37px'
            >
                <DescriptionLabel>
                    請輸入新的電子信箱，我們會將驗證碼寄送至新的電子信箱，請輸入信件中的驗證碼以完成會員建立程序，謝謝。
                </DescriptionLabel>
            </Box>
        );
    };

    const emailField = () => {
        return (
            <Box
                marginTop='10px'
                marginBottom='20px'
            >
                <TextField
                    autoComplete='off'
                    fullWidth
                    id='userid'
                    placeholder='請輸入新的Email'
                    sx={{ pt: "10px" }}
                    inputRef={inputUserEmail}
                />
            </Box>
        );
    };

    return (
        <>
            <Box sx={sxContainer}>
                {navigation() /* navigation bar */}
                <Box
                    sx={{ backgroundColor: "#FFFFFFF" }}
                    padding='16px'
                >
                    {description()}
                    {emailField()}
                </Box>
                {bottomPanel() /* 驗證按鈕 */}
            </Box>

            <Dialog
                showDialog={showAlert}
                setShowDialog={setShowAlert}
                title={alertTitle.current}
                message={alertMessage.current}
                actionLabel='確定'
                actionHandler={() => setShowAlert(false)}
            />
        </>
    );
}
