import { Typography } from "@mui/material";
import { styled } from "@mui/system";

const Subtitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.main,
    fontSize: "17px",
    fontWeight: 500,
    textAlign: "center",
}));

export default Subtitle;
