import Dropdown from "./Dropdown";
import { useState } from "react";
import { Stack, FormHelperText, TextField } from "@mui/material";

export default function TwoDependentDropdown({ data, spacing, title, name, showError, onChange }) {
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [remark, setRemark] = useState('');

  const handleCategoryChange = (e) => {
    const selectedValue = e.target.value;
    setCategory(selectedValue); 
    setSubCategory(''); 
    setRemark('');
    onChange({ value: '', remark: '' });
  };

  const handleSubCategoryChange = (e) => {
    const selectedValue = e.target.value;
    setSubCategory(selectedValue);
    setRemark('');
    onChange({ value: selectedValue, remark: '' }); 
  };

  const handleRemarkChange = (e) => {
    const updatedRemark = e.target.value;
    setRemark(updatedRemark); 
    onChange({ value: subCategory, remark: updatedRemark }); 
  };
  
  const categoryOptions = data.options.categories;
  const subCategoryOptions = categoryOptions ? data.options.subCategories[category] || [] : [];
  const currentOption = subCategory && subCategoryOptions.find(opt => opt.value === subCategory);

  return (
    <Stack spacing={spacing}>
      <Dropdown
        name={`${name}_categories`} 
        fullWidth
        key='categories'
        options={categoryOptions}
        value={category}
        onChange={handleCategoryChange}
        defaultLabel="請選擇職業"
        isDisabled={false}
      />
      <Dropdown
        name={`${name}_subCategories`} 
        fullWidth
        key='subCategories'
        options={subCategoryOptions}
        value={subCategory}
        onChange={handleSubCategoryChange}
        defaultLabel="請選擇職業類別"
        isDisabled={category ? false : true}
      /> 
      {currentOption?.textbox && (
        <TextField 
          variant="standard" 
          style={{marginTop:'8px'}}
          label={subCategoryOptions.find(opt => opt.value === subCategory)?.annotation}
          value={remark}
          onChange={handleRemarkChange}
        />
      )}
      <FormHelperText error id={`${name}-error-text`}>{showError && `請填寫: ${title}`}</FormHelperText>
    </Stack>
  );
}
