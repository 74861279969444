import React, { useState } from 'react';
import { TitleLabel, SubTitleLabel } from '../../components/Label';
import Panel from '../../components/Panel';
import { styled } from '@mui/system';
import {
  Box,
  TextField,
  Tab,
  Tabs,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import Divider from '@mui/material/Divider';
import Dialog from '../../components/Dialog';
// import {
//     countries,
//     getDistricts,
//     getDistrictCode,
//     getDonees,
//     getDoneeCode,
//     getCarrierTypes,
// } from "../../utils/Invoice";
import useInvoice from '../../hooks/useInvoice';

export default function Invoice({
  carrierRef,
  phoneBarcodeRef,
  citizenDigitalRef,
  zipCodeRef,
  cityRef,
  districtRef,
  addressRef,
  doneeCodeRef,
  doneeRef,
  invoiceTabRef,
  setTab,
  billNameRef,
  invoiceInputFocus,
  setInvoiceInputFocus,
}) {
  const [showCarrierDialog, setShowCarrierDialog] = useState(false);
  const [showcityDialog, setShowcityDialog] = useState(false);
  const [showDistrictDialog, setShowDistrictDialog] = useState(false);
  const [showDoneeDialog, setShowDoneeDialog] = useState(false);
  const {
    countries,
    getDistricts,
    getDistrictCode,
    getDonees,
    getDoneeCode,
    getCarrierTypes,
  } = useInvoice();

  const StyledTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    backgroundColor: '#E8E8E8',
    borderRadius: '8px',
  });

  const StyledTabLeft = styled(Tab)({
    marginTop: '6px',
    marginLeft: '6px',
    marginRight: '3px',
    marginBottom: '6px',
    '&.Mui-selected': {
      backgroundColor: '#FFF',
      borderRadius: '8px',
    },
  });
  const StyledTabRight = styled(Tab)({
    marginTop: '6px',
    marginLeft: '3px',
    marginRight: '6px',
    marginBottom: '6px',
    '&.Mui-selected': {
      backgroundColor: '#FFF',
      borderRadius: '8px',
    },
  });

  const txtInputProps = {
    disableUnderline: true,
    style: {
      fontSize: '15px',
    },
  };

  const sxTextField = {
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: 'black',
    },
  };

  const InputBox = styled(Box)(({ theme }) => ({
    backgroundColor: '#FAFAFA',
    width: '100%',
    height: '56px',
    borderRadius: '9px',
  }));

  const handlePhoneBarcodeInput = (value) => {
    phoneBarcodeRef.current = value;
  };

  const handleCitizenDigitalInput = (value) => {
    citizenDigitalRef.current = value;
  };

  const handleAddrInput = (value) => {
    addressRef.current = value;
  };

  const handleCarrierClick = (item) => {
    carrierRef.current = item;
    setShowCarrierDialog(false);
  };

  const handlecityClick = (item) => {
    cityRef.current = item;
    setShowcityDialog(false);
    getDistricts(cityRef.current);
    districtRef.current = null;
    addressRef.current = null;
  };

  const handleDistrictClick = (item) => {
    districtRef.current = item;
    zipCodeRef.current = getDistrictCode(cityRef.current, item);
    setShowDistrictDialog(false);
  };

  const handleDoneeClick = (item) => {
    doneeRef.current = item;
    doneeCodeRef.current = getDoneeCode(item);
    setShowDoneeDialog(false);
  };

  const handleTabChange = (event, newTabIndex) => {
    invoiceTabRef.current = newTabIndex;
    setTab(newTabIndex);
  };

  const invoiceDialog = (
    title,
    dataList,
    showDialog,
    setShowDialog,
    ref,
    handle,
  ) => {
    return (
      <Dialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        title={title}>
        <List
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
          }}>
          {dataList.map((item, index, data) => {
            if (index + 1 === data.length) {
              return (
                <React.Fragment key={index}>
                  <Box
                    display="flex"
                    alignItems="center"
                    marginRight="15px"
                    key={index}>
                    <ListItem
                      sx={{ height: '56px' }}
                      onClick={() => handle(item)}>
                      <ListItemText
                        primary={
                          <SubTitleLabel
                            color="#000000"
                            fontSize="15px"
                            fontWeight="bold">
                            {item}
                          </SubTitleLabel>
                        }
                      />
                    </ListItem>
                    {ref.current === item && <CheckIcon />}
                  </Box>
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment key={index}>
                  <Box display="flex" alignItems="center" marginRight="15px">
                    <ListItem
                      sx={{
                        height: '56px',
                      }}
                      onClick={() => handle(item)}>
                      <ListItemText
                        primary={
                          <SubTitleLabel
                            color="#000000"
                            fontSize="15px"
                            fontWeight="bold">
                            {item}
                          </SubTitleLabel>
                        }
                      />
                    </ListItem>
                    {ref.current === item && <CheckIcon />}
                  </Box>
                  <Divider variant="middle" component="li" />
                </React.Fragment>
              );
            }
          })}
        </List>
      </Dialog>
    );
  };

  return (
    <>
      <Box marginRight="16px" marginLeft="16px" paddingBottom="24px">
        <Panel>
          <Box
            sx={{
              paddingTop: '16px',
              paddingLeft: '16px',
              paddingRight: '16px',
            }}>
            <StyledTabs
              variant="fullWidth"
              value={invoiceTabRef.current}
              onChange={handleTabChange}>
              <StyledTabLeft
                label={
                  invoiceTabRef.current === 0 ? (
                    <TitleLabel
                      fontSize="17px"
                      fontWeight="bold"
                      color="#000000">
                      個人發票
                    </TitleLabel>
                  ) : (
                    <TitleLabel
                      fontSize="17px"
                      fontWeight="bold"
                      color="#6F6F6F">
                      個人發票
                    </TitleLabel>
                  )
                }
              />
              <StyledTabRight
                label={
                  invoiceTabRef.current === 1 ? (
                    <TitleLabel
                      fontSize="17px"
                      fontWeight="bold"
                      color="#000000">
                      捐贈發票
                    </TitleLabel>
                  ) : (
                    <TitleLabel
                      fontSize="17px"
                      fontWeight="bold"
                      color="#6F6F6F">
                      捐贈發票
                    </TitleLabel>
                  )
                }
              />
            </StyledTabs>
          </Box>
          <Box sx={{ padding: 2 }}>
            {invoiceTabRef.current === 0 && (
              <Box>
                <InputBox
                  display="flex"
                  alignItems="center"
                  sx={{
                    padding: '16px',
                    border: 1,
                    marginBottom: '8px',
                    borderColor: '#C1C1C1',
                    backgroundColor: '#FFFFFF',
                    marginRight: '5px',
                  }}
                  onClick={() => setShowCarrierDialog(true)}>
                  <TextField
                    variant="standard"
                    InputProps={txtInputProps}
                    hiddenLabel
                    autoComplete="off"
                    fullWidth
                    inputProps={{
                      min: 0,
                      style: {
                        textAlign: 'left',
                      },
                    }}
                    placeholder="OP 加密資產存摺會員載具"
                    sx={sxTextField}
                    value={carrierRef.current}
                    disabled
                  />
                  <ArrowDropDownIcon />
                </InputBox>
                {carrierRef.current === '手機條碼載具' ? (
                  <InputBox
                    display="flex"
                    alignItems="center"
                    sx={{
                      padding: '16px',

                      border: 1,
                      borderColor: '#C1C1C1',
                    }}>
                    <TextField
                      variant="standard"
                      InputProps={txtInputProps}
                      hiddenLabel
                      autoComplete="off"
                      fullWidth
                      inputProps={{
                        min: 0,
                        style: { textAlign: 'left' },
                      }}
                      placeholder="請輸入含/的手機條碼載具"
                      defaultValue={phoneBarcodeRef.current}
                      sx={sxTextField}
                      onChange={(e) => {
                        handlePhoneBarcodeInput(e.target.value);
                      }}
                      autoFocus={invoiceInputFocus}
                      onClick={() => {
                        setInvoiceInputFocus(true);
                      }}
                    />
                  </InputBox>
                ) : (
                  ''
                )}
                {carrierRef.current === '自然人憑證條碼載具' ? (
                  <InputBox
                    display="flex"
                    alignItems="center"
                    sx={{
                      padding: '16px',
                      border: 1,
                      borderColor: '#C1C1C1',
                    }}>
                    <TextField
                      variant="standard"
                      InputProps={txtInputProps}
                      hiddenLabel
                      autoComplete="off"
                      fullWidth
                      inputProps={{
                        min: 0,
                        style: { textAlign: 'left' },
                      }}
                      placeholder="請輸入卡片右下方16碼大寫英數字"
                      defaultValue={citizenDigitalRef.current}
                      sx={sxTextField}
                      onChange={(e) => {
                        handleCitizenDigitalInput(e.target.value);
                      }}
                      autoFocus={invoiceInputFocus}
                      onClick={() => {
                        setInvoiceInputFocus(true);
                      }}
                    />
                  </InputBox>
                ) : (
                  ''
                )}
                {carrierRef.current === '個人紙本發票' ? (
                  <>
                    <Divider
                      sx={{
                        marginBottom: '15px',
                        marginTop: '18px',
                      }}
                    />

                    <TitleLabel
                      fontSize="18px"
                      color="#070707"
                      fontWeight="bold">
                      紙本發票寄送資訊*
                    </TitleLabel>

                    <SubTitleLabel>
                      自動帶入會員中心資料，目前登記資料如下，收件地址可更改，更改後將同步更新會員中心通訊地址
                    </SubTitleLabel>

                    <InputBox
                      display="flex"
                      alignItems="center"
                      sx={{
                        padding: '16px',
                        marginTop: '8px',
                        marginBottom: '8px',
                        border: 1,
                        borderColor: '#C1C1C1',
                        backgroundColor: '#D2D2D2',
                      }}>
                      <TextField
                        variant="standard"
                        InputProps={txtInputProps}
                        hiddenLabel
                        disabled={true}
                        autoComplete="off"
                        fullWidth
                        inputProps={{
                          min: 0,
                          style: {
                            textAlign: 'left',
                          },
                        }}
                        defaultValue={billNameRef.current}
                        sx={sxTextField}
                      />
                    </InputBox>
                    <Box display="flex">
                      <InputBox
                        display="flex"
                        alignItems="center"
                        sx={{
                          padding: '16px',
                          border: 1,
                          borderColor: '#C1C1C1',
                          backgroundColor: '#FFFFFF',
                          marginRight: '5px',
                        }}
                        onClick={() => setShowcityDialog(true)}>
                        <TextField
                          variant="standard"
                          InputProps={txtInputProps}
                          hiddenLabel
                          autoComplete="off"
                          fullWidth
                          inputProps={{
                            min: 0,
                            style: {
                              textAlign: 'left',
                            },
                          }}
                          placeholder="請選擇縣市"
                          sx={sxTextField}
                          value={cityRef.current}
                          disabled
                        />
                        <ArrowDropDownIcon />
                      </InputBox>
                      <InputBox
                        display="flex"
                        alignItems="center"
                        sx={{
                          padding: '16px',
                          border: 1,
                          borderColor: '#C1C1C1',
                          backgroundColor: '#FFFFFF',
                          marginLeft: '5px',
                        }}
                        onClick={
                          cityRef.current !== null
                            ? () => setShowDistrictDialog(true)
                            : null
                        }>
                        <TextField
                          variant="standard"
                          InputProps={txtInputProps}
                          hiddenLabel
                          autoComplete="off"
                          fullWidth
                          inputProps={{
                            min: 0,
                            style: {
                              textAlign: 'left',
                            },
                          }}
                          placeholder="選擇鄉鎮市區"
                          sx={sxTextField}
                          value={districtRef.current}
                          disabled
                        />
                        <ArrowDropDownIcon />
                      </InputBox>
                    </Box>
                    <InputBox
                      display="flex"
                      alignItems="center"
                      sx={{
                        padding: '16px',
                        marginTop: '8px',
                        border: 1,
                        borderColor: '#C1C1C1',
                      }}>
                      <TextField
                        variant="standard"
                        InputProps={txtInputProps}
                        hiddenLabel
                        autoComplete="off"
                        fullWidth
                        inputProps={{
                          min: 0,
                          style: {
                            textAlign: 'left',
                          },
                        }}
                        placeholder="填寫詳細地址"
                        defaultValue={addressRef.current}
                        sx={sxTextField}
                        onChange={(e) => {
                          handleAddrInput(e.target.value);
                        }}
                        autoFocus={invoiceInputFocus}
                        onClick={() => {
                          setInvoiceInputFocus(true);
                        }}
                      />
                    </InputBox>
                  </>
                ) : (
                  ''
                )}
              </Box>
            )}
            {invoiceTabRef.current === 1 && (
              <InputBox
                display="flex"
                alignItems="center"
                sx={{
                  padding: '16px',
                  border: 1,
                  borderColor: '#C1C1C1',
                  backgroundColor: '#FFFFFF',
                }}
                onClick={() => setShowDoneeDialog(true)}>
                <TextField
                  variant="standard"
                  InputProps={txtInputProps}
                  hiddenLabel
                  autoComplete="off"
                  fullWidth
                  inputProps={{
                    min: 0,
                    style: {
                      textAlign: 'left',
                    },
                  }}
                  placeholder="請選擇捐贈單位"
                  sx={sxTextField}
                  value={doneeRef.current}
                  disabled
                />
                <ArrowDropDownIcon />
              </InputBox>
            )}
          </Box>
        </Panel>
      </Box>
      {invoiceDialog(
        '個人發票類型',
        getCarrierTypes(),
        showCarrierDialog,
        setShowCarrierDialog,
        carrierRef,
        handleCarrierClick,
      )}
      {invoiceDialog(
        '請選擇縣市',
        countries,
        showcityDialog,
        setShowcityDialog,
        cityRef,
        handlecityClick,
      )}
      {invoiceDialog(
        '請選擇鄉鎮市區',
        getDistricts(cityRef.current),
        showDistrictDialog,
        setShowDistrictDialog,
        districtRef,
        handleDistrictClick,
      )}
      {invoiceDialog(
        '請選擇捐贈單位',
        getDonees(),
        showDoneeDialog,
        setShowDoneeDialog,
        doneeRef,
        handleDoneeClick,
      )}
    </>
  );
}
