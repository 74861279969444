import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Stack, Button } from '@mui/material';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import { Label } from '../../components/Label';
import Panel from '../../components/Panel';
import BottomPanel from '../../components/BottomPanel';
import { styled } from '@mui/system';
import Section from './component/Section';
import userTerms from './userTerms'

const sxContainer = {
  pt: `${navigationBarHeight}px`,
};

const TradeButton = styled(Button)(({ theme }) => ({
  color: '#FFF',
  fontSize: '17px',
  fontWeight: 'bold',
  borderRadius: '9px',
}));

export default function UserTermsPage() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const response = userTerms;

  const handleBack = () => {
    if (state) {
      if (state.previousPage === '/signUp')
        navigate('/signUp', { state: { previousPage: '/terms' } });
    } else navigate(-1);
  };

  const navigation = () => {
    return <NavigationBar title="OP加密資產存摺 使用條款" leftButtonHandler={handleBack} />;
  };

  return (
    <Box sx={sxContainer}>
      {navigation() /* navigation bar */}
      <Box p='20px 16px'>
        <Panel>
          <Stack p='16px'>
            {response.description && response.description.map((des,index)=>(
              <Label pb='16px' sx={{'textAlign':'justify'}} key={`des-${index}`}>{des}</Label>
            ))}
            {response.sections.map((section, index) => (
              <Section key={index} section={section} />  
            ))}
          </Stack>
        </Panel>
      </Box>

      <BottomPanel>
        <TradeButton
          onClick={handleBack}
          variant="contained"
          fullWidth
          disableElevation
          type="submit">
          確認
        </TradeButton>
      </BottomPanel>
    </Box>
  );
}
