import { useContext, useEffect, useRef, useState } from 'react';
import { Box, Stack, Button, FormHelperText } from '@mui/material';
import { styled } from '@mui/system';
import { navigationBarHeight } from '../../components/Layout';
import NavigationBar from '../../components/NavigationBar';
import Panel from '../../components/Panel';
import { Label } from '../../components/Label';
import { TitleLabel } from '../../components/Label';
import Dialog from '../../components/Dialog';
import BottomPanel from '../../components/BottomPanel';
import RadioButtonsGroup from './components/RadioButtonsGroup';
import CheckboxGroup from './components/CheckboxGroup';
import TwoDependentDropdown from './components/TwoDependentDropdown';
import SwipeableTemporaryDrawer from './components/SwipeableTemporaryDrawer';
import FileUploadBox from './components/FileUploadBox';
import useImageUploader from './hooks/useImageUploader';
import EddImageList from './components/EddImageList';
import AlertMessage from '../../components/AlertMessage';
import { EddAPI } from '../../apis/EddAPI';
import { UserContext } from '../../UserContext';
import { useNavigate } from 'react-router-dom';

function RadioButtonQuestion({
  title,
  options,
  name,
  selectedValue,
  showError,
  onChange,
}) {
  return (
    <Stack spacing="16px">
      <TitleLabel id={title}>{title}</TitleLabel>
      <RadioButtonsGroup
        title={title}
        options={options}
        name={name}
        selectedValue={selectedValue}
        showError={showError}
        onChange={onChange}
      />
    </Stack>
  );
}

function CheckboxQuestion({ title, options, name, selectedValue, showError, onChange }) {
  return (
    <Stack spacing="16px">
      <TitleLabel id={title}>{title}</TitleLabel>
      <CheckboxGroup
        title={title}
        options={options}
        name={name}
        selectedValue={selectedValue}
        showError={showError}
        onChange={onChange}
      />
    </Stack>
  );
}

function DropDownQuestion({ title, options, name, showError, onChange }) {
  return (
    <Stack spacing="16px">
      <TitleLabel id={title}>{title}</TitleLabel>
      <TwoDependentDropdown
        data={options}
        spacing="16px"
        name={name}
        title={title}
        showError={showError}
        onChange={onChange}
      />
    </Stack>
  );
}

function Drawer({
  value,
  title,
  content,
  descriptItem,
  handleUpload,
  handleDelete,
  uploadedImage,
  showError
}) {
  return (
    <Stack spacing="16px" key={title}>
      <TitleLabel id={title}>{title}</TitleLabel>
      <SwipeableTemporaryDrawer
        anchor="bottom"
        onUpload={handleUpload}
        descriptItem={descriptItem}
        images={uploadedImage}
        onDelete={handleDelete}>
        <FileUploadBox content={content} />
      </SwipeableTemporaryDrawer>
      <EddImageList images={uploadedImage} onDelete={handleDelete} />
      <FormHelperText id={`${value}-error-text`} error>{showError && `請填寫: ${title}`}</FormHelperText>
    </Stack>
  );
}

const TradeButton = styled(Button)(({ theme }) => ({
  color: '#FFF',
  fontSize: '17px',
  fontWeight: 'bold',
  borderRadius: '9px',
}));

export default function Edd() {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [alert, setAlert] = useState('');
  const [formState, setFormState] = useState({});
  const [uploadedFundSourceImages, setUploadedFundSourceImages] = useState([]);
  const [uploadedAddressImages, setUploadedAddressImages] = useState([]);

  const { setLoading, setUser, setTabIndex, setNavTitle } =
    useContext(UserContext);
  const [formData, setFormData] = useState([]);
  const [orderNumber, setOrderNumber] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState({})
  const alertTitle = useRef('');
  const alertMessage = useRef('');
  

  const {
    handleImageUpload: handleFundSourceImageUpload,
    handleImageDelete: handleFundSourceImageDelete,
  } = useImageUploader(uploadedFundSourceImages, setUploadedFundSourceImages);
  const {
    handleImageUpload: handleAddressImageUpload,
    handleImageDelete: handleAddressImageDelete,
  } = useImageUploader(uploadedAddressImages, setUploadedAddressImages);

  //GET API: get edd-options
  useEffect(() => {
    async function getEddField() {
      setShowAlert(false);
      setLoading(true);
      const response = await EddAPI.getEddOptions();
      setLoading(false);
      if (response?.error) {
        console.log(response.error);
        if (response.error.message === 'Invalid authentication.') {
          alertTitle.current = '發生錯誤，請重新登入';
          alertMessage.current = '您的登入已過期，請重新登入';
          setShowAlert(true);
        }
      } else {
        setFormData(response.formData);
        setOrderNumber(response.orderNumber);
      }
    }
    getEddField();
  }, []);

  const handleBack = () => {
    clearTempFile();

    setTabIndex(0);
    setNavTitle('OP加密資產存摺');
    navigate('/');
  };

  // POST API: createEdd
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // 必填檢查
    let allFieldCheck = checkFormValidity();
    if (!allFieldCheck) {
      return;
    } else {
      setLoading(true);
      const formData = new FormData(formRef.current);
      const careerTypeSubCategory = formData.get('careerType_subCategories');
      const incomeTypeValue = formData.getAll('incomeType');

      //delete 不須上傳db的formData資料
      formData.delete('incomeType');
      formData.delete('careerType_categories');
      formData.delete('careerType_subCategories');

      // append 不是表單自動帶入資料者
      formData.append('orderNumber', orderNumber);
      formData.append('careerType', careerTypeSubCategory);
      formData.append('incomeType', incomeTypeValue);
      uploadedFundSourceImages.forEach((image) => {
        formData.append(`incomeFiles`, image.file);
      });
      uploadedAddressImages.forEach((image) => {
        formData.append(`addressFiles`, image.file);
      });

      // 將表單選項 JSON 字符化
      Object.entries(formState).forEach(([key, value]) => {
        formData.append(key, JSON.stringify(value));
      });

      //POST API: submit form
      const response = await EddAPI.createEdd(formData);
      const [status, data] = response;

      clearTempFile();

      if (data?.error) {
        console.log(data.error);
        if (status === 500) {
          const errorMessage = data.error.message;
          alertTitle.current = '發生錯誤，請確認後重試';
          alertMessage.current = errorMessage;
          setShowAlert(true);
        }
      } else {
        navigate(`${data.page}`);
        setUser(data?.user);
      }
      setLoading(false);
    }
  };

  //handler: 更新formState
  const handleFieldChange = (fieldName, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  //驗證必填
  function checkFormValidity() {
    const scrollToValues = [];
    formData.forEach((item) => {
      if (item.type === 'radioButton') {
        const selection = formState[item.value];
        if (!selection || !selection.value) {
          scrollToValues.push(item.value);
          setError(prev => ({
            ...prev,
            [item.value]: true,
          }));
        } else {
          const option = item.options.find(
            (opt) => opt.value === selection.value,
          );
          setError(prev => ({
            ...prev,
            [item.value]: false,
          }));
          if (option.textbox && selection.remark.trim() === '') {
            scrollToValues.push(item.value);
            setError(prev => ({
              ...prev,
              [item.value]: true,
            }));
          }
        }
      } else if (item.type === 'checkBox') {
        const selections = formState[item.value];
        if (!selections || selections.length === 0) {
          scrollToValues.push(item.value);
          setError(prev => ({
            ...prev,
            [item.value]: true,
          }));
        } else {
          selections.forEach((selection) => {
            const option = item.options.find(
              (opt) => opt.value === selection.value,
            );
            setError(prev => ({
              ...prev,
              [item.value]: false,
            }));
            if (option.textbox && selection.remark.trim() === '') {
              scrollToValues.push(item.value);
              setError(prev => ({
                ...prev,
                [item.value]: true,
              }));
            }
          });
        }
      } else if (item.type === 'dropDownMenu') {
        const selection = formState[item.value];
        if (!selection || !selection.value) {
          scrollToValues.push(item.value);
          setError(prev => ({
            ...prev,
            [item.value]: true,
          }));
        } else {
          setError(prev => ({
            ...prev,
            [item.value]: false,
          }));
          const subCategories = item.options.subCategories;
          for (const category in subCategories) {
            subCategories[category].forEach((subItem) => {
              if (
                subItem.value === selection.value &&
                subItem.textbox &&
                selection.remark.trim() === ''
              ) {
                scrollToValues.push(item.value);
                setError(prev => ({
                  ...prev,
                  [item.value]: true,
                }));
              }
            });
          }
        }
      } else if (item.type === 'uploadFile') {
        const uploadedFiles =
          item.value === 'fundSourceImage'
            ? uploadedFundSourceImages
            : uploadedAddressImages;
        if (uploadedFiles.length === 0) {
          scrollToValues.push(item.value);
          setError(prev => ({
            ...prev,
            [item.value]: true,
          }));
        } else {
          setError(prev => ({
            ...prev,
            [item.value]: false,
          }));
        }
      }
    });

    if (scrollToValues.length > 0) {
      const _id = `#${scrollToValues[0]}-error-text`;
      const ele = document.querySelector(_id);
      ele.scrollIntoView({ behavior: 'smooth', block: 'center' })
      return false;
    }
    return true;
  }
  /**清除暫存瀏覽器圖檔 */
  function clearTempFile() {
    uploadedFundSourceImages.forEach((image) => URL.revokeObjectURL(image.url));

    uploadedAddressImages.forEach((image) => URL.revokeObjectURL(image.url));
  }

  return (
    <>
      <Box sx={{ pt: `${navigationBarHeight}px` }}>
        <NavigationBar
          title="客戶基本資料確認"
          leftButtonHandler={handleBack}
        />
        <form ref={formRef} onSubmit={handleFormSubmit}>
          <Box p="19px 16px">
            <Panel>
              <Stack spacing="24px" p="16px">
                <Label>
                  為完整您的身分驗證，請完成以下資訊，為確保資料完整性，客服人員將與您聯絡，請注意來電。
                </Label>
                <Stack spacing="24px">
                  {formData.map((item) => {
                    if (item.type === 'radioButton') {
                      return (
                        <RadioButtonQuestion
                          name={item.value}
                          key={item.value}
                          title={item.title}
                          options={item.options}
                          selectedValue={formState[item.value]}
                          showError={error[item.value] === true}
                          onChange={(value) =>
                            handleFieldChange(item.value, value)
                          }
                        />
                      );
                    } else if (item.type === 'checkBox') {
                      return (
                        <CheckboxQuestion
                          name={item.value}
                          key={item.value}
                          title={item.title}
                          options={item.options}
                          selectedValue={formState[item.value]}
                          showError={error[item.value]}
                          onChange={(value) =>
                            handleFieldChange(item.value, value)
                          }
                        />
                      );
                    } else if (item.type === 'dropDownMenu') {
                      return (
                        <DropDownQuestion
                          name={item.value}
                          key={item.value}
                          title={item.title}
                          options={item}
                          selectedValue={formState[item.value]}
                          showError={error[item.value] === true}
                          onChange={(value) =>
                            handleFieldChange(item.value, value)
                          }
                        />
                      );
                    } else if (item.type === 'uploadFile') {
                      if (item.value === 'fundSourceImage') {
                        return (
                          <Drawer
                            key={item.value}
                            value={item.value}
                            title={item.title}
                            content={item.options[0].label}
                            descriptItem={[
                              '最近三個月薪資轉帳/網路銀行明細',
                              '網銀/存摺明細需有【薪資】字樣',
                            ]}
                            handleUpload={handleFundSourceImageUpload}
                            handleDelete={handleFundSourceImageDelete}
                            uploadedImage={uploadedFundSourceImages}
                            showError={error[item.value] === true}
                          />
                        );
                      } else if (item.value === 'addressImage') {
                        return (
                          <Drawer
                            key={item.value}
                            value={item.value}
                            title={item.title}
                            content={item.options[0].label}
                            descriptItem={[
                              '收件人為本人且有註明地址的水、電、瓦斯、網路帳單 (文件日期需在最近3個月內)',
                              '收件人為本人且有註明地址的銀行／信用卡對帳單（文件日期需在最近3個月內）',
                            ]}
                            handleUpload={handleAddressImageUpload}
                            handleDelete={handleAddressImageDelete}
                            uploadedImage={uploadedAddressImages}
                            showError={error[item.value] === true}
                          />
                        );
                      } else {
                        return null;
                      }
                    } else {
                      return null;
                    }
                  })}
                </Stack>
              </Stack>
            </Panel>
          </Box>
          <BottomPanel>
            <TradeButton
              variant="contained"
              fullWidth
              disableElevation
              type="submit">
              送出
            </TradeButton>
          </BottomPanel>
        </form>

        {/* alert */}
        <Dialog
          showDialog={showAlert}
          setShowDialog={setShowAlert}
          title={alertTitle.current}
          message={alertMessage.current}
          actionLabel="確定"
          actionHandler={() => {
            setShowAlert(false);
            if (alertMessage.current === '您的登入已過期，請重新登入') {
              navigate('/signIn');
            }
          }}
        />

        {/* Alert */}
        <AlertMessage alert={alert} setAlert={setAlert} />
      </Box>
    </>
  );
}
