export const maxVerifyCodeLength = 6;

export const phoneVerifyCodeReducer = (state, action) => {
  const cursor = { value: '|', animated: true };
  switch (action.type) {
    case 'addNumber':
      if (state.currentPos === maxVerifyCodeLength) {
        return state;
      } else {
        state.numbers[state.currentPos] = {
          value: action.payload,
          animated: false,
        };
        if (state.currentPos + 1 < maxVerifyCodeLength) {
          state.numbers[state.currentPos + 1] = cursor;
        }
        if (state.currentPos === maxVerifyCodeLength - 1) {
          state.verifyDisable = false;
        }
        return {
          ...state,
          currentPos: state.currentPos + 1,
        };
      }
    case 'delete':
      if (state.currentPos === maxVerifyCodeLength) {
        state.numbers[state.currentPos - 1] = cursor;
        state.verifyDisable = true;
        return {
          ...state,
          currentPos: state.currentPos - 1,
        };
      } else if (state.currentPos === 0) {
        return state;
      } else {
        state.numbers[state.currentPos] = {
          value: '',
          animated: false,
        };
        state.numbers[state.currentPos - 1] = cursor;
        return {
          ...state,
          currentPos: state.currentPos - 1,
        };
      }
    case 'reset':
      return {
        ...state,
        numbers: [{ value: '|', animated: true }].concat(
          Array(maxVerifyCodeLength - 1).fill({
            value: '',
            animated: false,
          }),
        ),
        currentPos: 0,
      };

    default:
      return state;
  }
};
