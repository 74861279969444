import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ImageListItemBar from "@mui/material/ImageListItemBar";

const EddImageList = ({ images, onDelete }) => {
    return (
        images.length > 0 && (
            <ImageList
                variant='masonry'
                cols={3}
                gap={8}
            >
                {images.map((image) => (
                    <ImageListItem key={image.id}>
                        <img
                            src={image.url}
                            alt={`Uploaded ${image.id}`}
                            loading='lazy'
                        />
                        <ImageListItemBar
                            sx={{
                                background: "none",
                            }}
                            position='top'
                            actionIcon={
                                <IconButton
                                    aria-label='delete'
                                    size='large'
                                    onClick={() => onDelete(image.id)}
                                >
                                    <HighlightOffIcon color='primary' />
                                </IconButton>
                            }
                            actionPosition='right'
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        )
    );
};

export default EddImageList;
