import { React } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Divider } from '@mui/material';
import NavigationBar from '../NavigationBar';
import Panel from '../Panel';
import Icon from '../Icon';
import { TitleLabel } from '../Label';

const IconLogo = ({ tradeType }) => {
  return (
    <Box
      marginTop="32px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      marginBottom="22px">
      <Icon src={`/images/img_${tradeType}.png`} width={100} height={100} />
    </Box>
  );
};

const Content = ({ bankRecords }) => {
  return (
    <Box
      sx={{
        width: '100%',
        padding: '16px',
      }}>
      <Divider />

      {bankRecords?.map((row, index) => (
        <Box
          key={row.title}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginTop={index ? '9px' : '23px'}>
          <TitleLabel fontSize="15px">{row.title}</TitleLabel>
          <TitleLabel fontSize="15px">{row.content}</TitleLabel>
        </Box>
      ))}
    </Box>
  );
};

const BankInfo = ({ icon, content }) => {
  return (
    <Box mt="27px" mb="24px">
      <Panel>
        {icon}
        {content}
      </Panel>
    </Box>
  );
};

export default function TradeDetailPanel({
  title,
  records,
  tradeType,
  originFrom,
}) {
  const navigate = useNavigate();
  const handleBack = async () => {
    navigate(-1, { state: { originFrom: originFrom } });
  };
  return (
    <>
      <NavigationBar
        leftButtonHandler={handleBack}
        hideLeftButton={false}
        title={title}
      />
      <BankInfo
        icon={<IconLogo tradeType={tradeType} />}
        content={<Content bankRecords={records} />}
      />
    </>
  );
}
