import Icon from "../../../components/Icon";
import { Box, Stack } from "@mui/material";
import { TitleLabel, Label } from "../../../components/Label";

export default function ResultTemplate({ title, desc, succeed }) {
    return (
        <Stack
            sx={{ mt: "40px" }}
            spacing={0}
            direction={"column"}
            alignItems='center'
        >
            <Box>
                {succeed ? (
                    <Icon
                        src='img_success.png'
                        width={120}
                        height={120}
                    />
                ) : (
                    <Icon
                        src='img_fail.png'
                        width={120}
                        height={120}
                    />
                )}
            </Box>
            <Box marginTop='20px'>
                <TitleLabel>{title}</TitleLabel>
            </Box>
            <Box
                width={"80%"}
                marginTop='10px'
                marginBottom='37px'
            >
                <Label
                    fontSize='15px'
                    align='center'
                >
                    {desc}
                </Label>
            </Box>
        </Stack>
    );
}
