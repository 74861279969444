import { Typography } from "@mui/material";
import { styled } from "@mui/system";

const Icon = styled("img", {
    shouldForwardProp: (prop) => prop !== "width" && prop !== "height",
})(({ theme, width, height }) => ({
    maxWidth: width ? width : 40,
    maxHeight: height ? height : 40,
}));

export default Icon;
