import React, { useContext } from 'react';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Stack } from '@mui/material';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import Icon from '../../components/Icon';
import { TitleLabel, Label } from '../../components/Label';
import BottomPanel from '../../components/BottomPanel';
import { UserContext } from '../../UserContext';
import { CS_EMAIL } from '../../constants/customerService';

const TradeButton = styled(Button)(({ theme }) => ({
  color: '#FFF',
  fontSize: '17px',
  fontWeight: 'bold',
  borderRadius: '9px',
}));

const sxContainer = {
  pt: `${navigationBarHeight}px`,
};

export default function KycFailedPage() {
    const { setTabIndex, setNavTitle } = useContext(UserContext);
    const navigate = useNavigate();

    const handleBack = () => {

        setTabIndex(0);
        setNavTitle("OP加密資產存摺")
        navigate("/");
    };

  const handleComplete = () => {
    window.location.href = `mailto:${process.env.REACT_APP_CS_EMAIL}`;
  };

    const navigation = () => {
        return (
            <NavigationBar
                title='身分認證'
                leftButtonHandler={handleBack}
            />
        );
    };

  const kycDoneDesc = () => {
    return (
      <Stack
        sx={{ mt: '150px' }}
        spacing={2}
        direction={'column'}
        alignItems="center">
        <Box>
          <Icon src="img_fail.png" width={122} height={122} />
        </Box>
        <Box>
          <TitleLabel>身分驗證失敗</TitleLabel>
        </Box>
        <Box width={'100%'} marginTop="10px" marginBottom="37px">
          <Label fontSize="15px" align="center">
            您好，因身分驗證無法完成，請聯繫客服，我們將有專人為您服務，造成不便之處還請見諒，謝謝。
          </Label>
        </Box>
      </Stack>
    );
  };

  const bottomPanel = () => {
    return (
      <BottomPanel>
        <TradeButton
          variant="contained"
          fullWidth
          disableElevation
          onClick={handleComplete}>
          聯繫客服
        </TradeButton>
      </BottomPanel>
    );
  };
  return (
    <Box sx={sxContainer}>
      {navigation() /* navigation bar */}
      <Box padding="32px">{kycDoneDesc()}</Box>
      {bottomPanel() /* 進行身分驗證按鈕 */}
    </Box>
  );
}
