import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import NavigationBar from "../../components/NavigationBar";
import { navigationBarHeight } from "../../components/Layout";
import OrderInfo from "../../components/trade/OrderInfo";
import { OrderAPI } from "../../apis/OrderAPI";
import { UserContext } from "../../UserContext";
const sxContainer = {
    pt: `${navigationBarHeight}px`,
};

export default function OrderInfoPage() {
    const { setLoading } = useContext(UserContext);
    const navigate = useNavigate();
    const { state } = useLocation();
    const {
        order: { doneReason, id },
        nextNavigation,
    } = state

    const [order, setOrder] = useState(state.order);

    useEffect(() => {
        const fetchOrder = async () => {
            setLoading(true);
            const order = await OrderAPI.getOrder(id);
            setOrder(order);
            setLoading(false);
        };
        if (doneReason !== "cancel") {
            fetchOrder();
        }
    }, [doneReason, id, setLoading]);

    const handleBack = () => {
        if (nextNavigation) {
            navigate(nextNavigation);
        } else {
            navigate(-1);
        }
    };

    return (
        <Box sx={sxContainer}>
            <NavigationBar
                title='訂單詳情'
                leftButtonHandler={handleBack}
            />
            <OrderInfo order={order} />
        </Box>
    );
}
