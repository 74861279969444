import { useRef, forwardRef, useContext, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button, Box, Divider } from '@mui/material';
import Icon from '../../components/Icon';
import NavigationBar from '../../components/NavigationBar';
import { Label, SubTitleLabel } from '../../components/Label';
import StyledButton from '../../components/StyledButton';
import { useTheme } from '@mui/material/styles';
import InputLabel from '../../components/InputLabel';
import { UserAPI } from '../../apis/UserAPI';
import { UserContext } from '../../UserContext';
import Dialog from '../../components/Dialog';

const Logo = () => {
  return (
    <Box
      mt="30px"
      pb="18px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center">
      <Icon src="/images/logo.png" width="66px" height="69px" />
      <Icon
        src="/images/logoTitle.png"
        width="100%"
        height="19px"
        sx={{ marginTop: '13px' }}
      />
    </Box>
  );
};

const Dividers = ({ dividerColor }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-evenly"
      overflow="hidden">
      <Divider sx={{ width: '100%', background: dividerColor }} />
      <SubTitleLabel p="26px 13px" fontSize="15px">
        or
      </SubTitleLabel>
      <Divider sx={{ width: '100%', background: dividerColor }} />
    </Box>
  );
};

const SignInButton = forwardRef((_, { phoneNumberRef, passwordRef }) => {
  const { setLoading, setUser, setTabIndex, setNavTitle } = useContext(UserContext);
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const errorMessageRef = useRef('');

  const handleLogin = async () => {
    if (
      phoneNumberRef.current?.value === '' ||
      passwordRef.current?.value === ''
    ) {
      errorMessageRef.current = '輸入的資料格式不正確，請確認後重試';
      setShowDialog(true);
    } else {
      setLoading(true);
      const payload = {
        msisdn: phoneNumberRef.current?.value,
        password: passwordRef.current?.value,
      };
      const response = await UserAPI.getUserWithPhoneAndPassword(payload);
      setLoading(false);

      if (response?.error) {
        errorMessageRef.current = response?.error.message;
        setShowDialog(true);
      } else if (response?.user) {
        setUser(response?.user);
        setTabIndex(0);
        setNavTitle("OP加密資產存摺");
        navigate('/');
      }
    }
  };

  return (
    <>
      <StyledButton
        variant="contained"
        fullWidth
        disableElevation
        sx={{
          padding: '16px 0',
          height: '48px',
          boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.15)',
        }}
        onClick={() => handleLogin()}>
        登入
      </StyledButton>

      <Dialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        title="登入失敗"
        message={errorMessageRef.current}
        actionLabel="確定"
        actionHandler={() => setShowDialog(false)}
      />
    </>
  );
});

const BottomDesc = ({ mainColor }) => {
  return (
    <Box pt="116px" display="flex" justifyContent="center" alignItems="center">
      <Label fontWeight="bold" fontSize="15px">
        還沒有帳號？
      </Label>
      <SubTitleLabel
        fontWeight="bold"
        fontSize="15px"
        sx={{ color: mainColor, textDecoration: 'none' }}
        component={Link}
        to="/signUp">
        立即註冊
      </SubTitleLabel>
    </Box>
  );
};

export default function SignInPage() {
  const navigate = useNavigate();
  const handleBack = () => navigate('/landing');
  const phoneNumberRef = useRef(null);
  const passwordRef = useRef(null);
  const theme = useTheme();
  const {
    palette: {
      primary: { main },
      grey,
    },
  } = theme;

  return (
    <>
      <NavigationBar noShadow leftButtonHandler={handleBack} title="登入" />
      <Logo />
      <InputLabel
        inputLabel="帳號"
        id="phoneNumber"
        placeholder="請輸入使用的手機號碼"
        ref={phoneNumberRef}
      />
      <InputLabel
        inputLabel="密碼"
        id="password"
        placeholder="請輸入帳號的密碼"
        type="password"
        ref={passwordRef}
      />
      <Label
        fontSize="15px"
        fontWeight="bold"
        color={main}
        sx={{ margin: '8px 0 16px', textAlign: 'right' }}>
        <Button variant="text" color="primary" onClick={() => navigate("/resetPassword/phoneNumber")}>忘記密碼</Button>
      </Label>      
      <SignInButton ref={{ phoneNumberRef, passwordRef }} />
      <Dividers dividerColor={grey[100]} />
      <BottomDesc mainColor={main} />
    </>
  );
}
