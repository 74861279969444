import { React, useState, useRef, useContext, useEffect } from 'react';
import { styled } from '@mui/system';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, Box, Stack } from '@mui/material';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import Icon from '../../components/Icon';
import { UserContext } from '../../UserContext';
import BottomPanel from '../../components/BottomPanel';
import { UserAPI } from '../../apis/UserAPI';
import Dialog from '../../components/Dialog';
import { Label } from '../../components/Label';
import StyledButton from '../../components/StyledButton';
import { useEffectOnce } from '../../utils/UseEffectOnce';
import DigitLabelBox from './components/DigitLabelBox';
import HiddenTextField from './components/HiddenTextField';
import moment from 'moment/moment';

const maxVerifyCodeLength = 6;
const sxContainer = {
  pt: `${navigationBarHeight}px`,
};
const DescriptionLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.main,
  fontSize: '15px',
  fontWeight: 'medium',
  textAlign: 'center',
}));

export default function EmailVerifyPage() {
  const countDown = moment.duration(60, 'seconds');
  const { setLoading, user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [verifyCode, setVerifyCode] = useState('');
  const [disable, setDisable] = useState(true);
  const [disableResend, setDisableResend] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [showResendFailAlert, setShowResendFailAlert] = useState(false);
  const alertTitle = useRef('');
  const textInput = useRef(null);
  const [showCountDown, setShowCountDown] = useState(false);

  const [duration, setDuration] = useState(countDown);
  const intervalRef = useRef();
  const { state } = useLocation();

  useEffectOnce(() => {
    handleGetEmailExpireTime();
  }, []);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setDuration(moment.duration(duration.asMilliseconds() - 1000));
    }, 1000);
    return () => clearInterval(intervalRef.current);
  }, [duration]);

  useEffect(() => {
    if (duration.asMilliseconds() < 0) {
      clearInterval(intervalRef.current);
      setShowCountDown(false);
      setDisableResend(false);
    }
  }, [duration]);

  const handleBack = () => {
    navigate('/');
  };

  const navigation = () => {
    return <NavigationBar title="輸入驗證碼" leftButtonHandler={handleBack} />;
  };

  const handleVerifyCodeUpdate = (e) => {
    const code = e.target.value.replace(/\D/g, '');
    if (code.length > maxVerifyCodeLength) {
      return;
    }
    setVerifyCode(code);
    setDisable(code.length === maxVerifyCodeLength ? false : true);
  };

  const handleVerifyCode = async () => {
    setLoading(true);
    const responseData = await UserAPI.emailVerifyCode({
      code: verifyCode,
    });
    setLoading(false);

    if (responseData) {
      setUser(responseData);
      if (state.entry === '/signUp') {
        navigate('/signUpCompleted');
      } else if (state.entry === '/changePhoneNumber') {
        navigate('/changePhoneNumberSucceed');
      }
    } else {
      alertTitle.current = '驗證失敗';
      setShowAlert(true);
    }
  };

  const handleGetEmailExpireTime = async () => {
    setShowAlert(false);
    setLoading(true);
    const success = await UserAPI.getEmailExpireTime();
    setLoading(false);

    if (success) {
      setDuration(countDown);
      setShowCountDown(true);
      setDisableResend(true);
      setVerifyCode('');
    } else {
      handleResendVerifyCode();
    }
  };

  const handleResendVerifyCode = async () => {
    setShowAlert(false);
    setLoading(true);
    const success = await UserAPI.resendEmailVerifyCode();
    setLoading(false);

    if (success) {
      setDuration(countDown);
      setShowCountDown(true);
      setDisableResend(true);
      setVerifyCode('');
    } else {
      setShowResendFailAlert(true);
    }
  };

  const handleChangeEmail = () => {
    setShowAlert(false);
    navigate('/changeEmail', { state: { entry: '/signUp' } });
  };

  const verifyDescription = () => {
    return (
      <Box width={'100%'} marginTop="28px" marginBottom="37px">
        <DescriptionLabel>
          驗證碼已傳送至{user?.email}，請輸入驗證碼以完成會員建立程序
        </DescriptionLabel>
      </Box>
    );
  };

  const getDigitGroup = () => {
    let digits = [];
    for (let i = 0; i < maxVerifyCodeLength; i++) {
      const isEditing = i === verifyCode.length;
      const value = isEditing ? '|' : verifyCode.charAt(i);
      digits.push(
        <DigitLabelBox
          key={`digit-${i}`}
          value={value}
          animated={isEditing}
          isEditing={isEditing}
        />,
      );
    }
    return (
      <Box
        display="flex"
        onClick={() => {
          textInput.current.focus();
        }}>
        {digits}
      </Box>
    );
  };

  const emailDesc = () => {
    return (
      <Stack
        spacing={0}
        direction={'column'}
        alignItems="center"
        sx={{ mt: '40px' }}>
        <Box>
          <Icon src="img_email.png" width={150} height={70} />
        </Box>

        <Box>{verifyDescription()}</Box>

        {getDigitGroup()}

        {/* 隱藏的input*/}
        <HiddenTextField
          inputRef={textInput}
          value={verifyCode}
          onChange={handleVerifyCodeUpdate}
        />

        <Box>
          {showCountDown ? (
            <Label
              fontSize="15px"
              fontWeight="bold"
              color="#717171"
              align="center">
              <span style={{ color: '#FF8F08' }}>
                {String(duration.minutes()).padStart(2, '0')}
                &nbsp;:&nbsp;
                {String(duration.seconds()).padStart(2, '0')}
              </span>
              &nbsp;後即可重新發送
            </Label>
          ) : (
            <Label
              fontSize="15px"
              fontWeight="bold"
              color="#717171"
              align="center">
              驗證碼已過期請選擇
            </Label>
          )}
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          marginTop="16px">
          <StyledButton
            variant="contained"
            fullWidth
            disableElevation
            sx={{ height: '48px', marginRight: '16px' }}
            disabled={disableResend}
            onClick={handleResendVerifyCode}>
            重新寄送驗證碼
          </StyledButton>
          {state.entry !== '/changePhoneNumber' && (
            <StyledButton
              variant="contained"
              fullWidth
              disableElevation
              sx={{ height: '48px' }}
              onClick={handleChangeEmail}>
              更改Email重寄
            </StyledButton>
          )}
        </Box>
      </Stack>
    );
  };

  const bottomPanel = () => {
    return (
      <BottomPanel>
        <StyledButton
          variant="contained"
          fullWidth
          disableElevation
          onClick={handleVerifyCode}
          disabled={disable}>
          驗證
        </StyledButton>
      </BottomPanel>
    );
  };

  return (
    <>
      <Box sx={sxContainer}>
        {navigation() /* navigation bar */}
        <Box padding="16px">{emailDesc()}</Box>
        {bottomPanel() /* 驗證按鈕 */}
      </Box>

      <Dialog
        showDialog={showAlert}
        setShowDialog={setShowAlert}
        title={alertTitle.current}
        message="您輸入的驗證碼有誤，請確認輸入內容是否正確。"
        actionLabel="確定"
        actionHandler={() => setShowAlert((prev) => !prev)}
      />
      <Dialog
        showDialog={showResendFailAlert}
        setShowDialog={setShowResendFailAlert}
        title="重新寄送失敗"
        message="請稍後再試。"
        actionLabel="確定"
        actionHandler={() => setShowResendFailAlert(false)}
      />
    </>
  );
}
