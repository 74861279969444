import { useContext, useCallback } from 'react';
import { Paper, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext';

import BarChartIcon from '@mui/icons-material/BarChart';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PersonIcon from '@mui/icons-material/Person';
import { tabBarHeight } from './Layout';

export default function TabBar() {
  const { tabIndex, setTabIndex, setNavTitle, user } = useContext(UserContext);
  const navigate = useNavigate();
  const isAssetPathVerified = useCallback(() => {
    if (
      user.emailVerifyStatus === 'succeed' &&
      (user.phoneVerifyStatus === 'intraUnverified' ||
        user.phoneVerifyStatus === 'interUnverified')
    ) {
      if (user.kycStatus === 'succeed' && user.amlStatus === 'succeed') {
        return true;
      }
      if (
        (user.kycStatus === 'succeed' || user.kycStatus === 'unverified') &&
        user.amlStatus === 'denied'
      ) {
        return true;
      }
    }
    return false;
  }, [user]);

  const isMemberPathVerified = useCallback(() => {
    if (
      user.emailVerifyStatus === 'succeed' &&
      (user.phoneVerifyStatus === 'intraUnverified' ||
        user.phoneVerifyStatus === 'interUnverified')
    ) {
      if (
        !(
          (user.kycStatus === 'succeed' || user.kycStatus === 'unverified') &&
          user.amlStatus === 'blocked'
        )
      ) {
        return true;
      }
    }
    return false;
  }, [user]);

  const sxTabBar = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: `${tabBarHeight}px`,
  };

  const tabItems = [
    {
      label: 'OP加密資產存摺',
      icon: <BarChartIcon fontSize="large" />,
      path: '/',
    },
    {
      label: '我的資產',
      icon: <AccountBalanceWalletIcon fontSize="large" />,
      path: '/asset',
    },
    {
      label: '會員中心',
      icon: <PersonIcon fontSize="large" />,
      path: '/member',
    },
  ];

  const tabHandler = (index) => {
    const tabItem = tabItems[index];
    const { path, label } = tabItem;

    if (path === '/' || isAssetPathVerified || isMemberPathVerified) {
      setNavTitle(label);
      setTabIndex(index);
    }
    navigate(path);
  };

  return (
    <>
      <Paper sx={sxTabBar} elevation={3}>
        <BottomNavigation
          // showLabels
          sx={{ marginTop: 0.5 }}
          value={tabIndex}
          onChange={(event, newValue) => {
            //setTabIndex(newValue);
            tabHandler(newValue);
          }}>
          {tabItems.map((item, index) => (
            <BottomNavigationAction
              key={index}
              // label={item.label}
              icon={item.icon}
            />
          ))}
        </BottomNavigation>
      </Paper>
    </>
  );
}
