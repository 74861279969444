import { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Stack, CircularProgress } from '@mui/material';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import { TitleLabel, Label } from '../../components/Label';
import Panel from '../../components/Panel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Dialog from '../../components/Dialog';
import { UserContext } from '../../UserContext';

const iconSize = '32px';
const sxAuthorizeContent = {
  display: 'flex',
  height: '50px',
  alignItems: 'center',
};

const sxContainer = {
  pt: `${navigationBarHeight}px`,
};

const Navigation = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { setTabIndex, setNavTitle } = useContext(UserContext);
  const [showDialog, setShowDialog] = useState(false);
  const handleBack = () => {
    if (
      state?.from === '/changeAccountInfo' ||
      state?.from === '/changeInvoice'
    ) {
      navigate('/member');
    } else {
      setTabIndex(0);
      setNavTitle('OP加密資產存摺');
      navigate('/');
    }
  };

  return (
    <>
      <NavigationBar title="會員建立與驗證" leftButtonHandler={handleBack} />
      <Dialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        title="身分驗證中"
        message="身分驗證正在進行中，大約需要1~2分鐘，完成後我們將會通知您，您可以選擇返回首頁或停留此頁等待驗證完成，謝謝。"
        actionLabel="返回首頁"
        actionHandler={() => navigate('/')}
        secondaryActionLabel="停留此頁"
        secondaryActionHandler={() => setShowDialog(false)}
      />
    </>
  );
};

const RegisterDesc = ({ text }) => {
  return (
    <Box width="100%" marginTop="36px">
      <Label fontSize="15px" align="center">
        {text}
      </Label>
    </Box>
  );
};

const DoneItem = ({ text }) => {
  return (
    <Box sx={sxAuthorizeContent}>
      <CheckCircleIcon style={{ fontSize: iconSize }} />
      <Box marginLeft="16px">
        <TitleLabel>{text}</TitleLabel>
      </Box>
    </Box>
  );
};

const ProgressItem = ({ text }) => {
  return (
    <Box sx={sxAuthorizeContent}>
      <CircularProgress color="inherit" size={'32px'} />
      <Box marginLeft="16px">
        <TitleLabel>{text}</TitleLabel>
      </Box>
    </Box>
  );
};

const RegisterContent = () => {
  return (
    <Box p="36px 16px 0">
      <Panel>
        <Stack p="32px" spacing="38px">
          <DoneItem text="會員帳號建立完成" />
          <ProgressItem text="會員身分驗證中" />
        </Stack>
      </Panel>
      <RegisterDesc text="系統正在驗證您的身分，整個時間約需數分鐘~兩個工作天完成，身分驗證後方能開始使用本服務，感謝您的耐心等候。" />
    </Box>
  );
};

export default function KycVerifyingPage() {
  return (
    <Box sx={sxContainer}>
      <Navigation />
      <RegisterContent />
    </Box>
  );
}
