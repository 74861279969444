import { useState, useRef, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Link,
  Stack,
  Paper,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import { UserAPI } from '../../apis/UserAPI';
import BottomPanel from '../../components/BottomPanel';
import Dialog from '../../components/Dialog';
import { UserContext } from '../../UserContext';
import validator from './validator';
import { Label } from '../../components/Label';
import StyledButton from '../../components/StyledButton';
import secureLocalStorage from 'react-secure-storage';
import TextInput from './components/TextInput';

const sxContainer = {
  p: `${navigationBarHeight}px 24px 0`,
};

const sxBottomCheckBoxBar = {
  position: 'fixed',
  bottom: '78px',
  left: 0,
  right: 0,
  padding: '16px 16px 0 16px',
  backgroundColor: '#fff',
};

const terms = [
  { title: '《隱私權條款》', path: '/privacy' },
  { title: '《使用者條款》', path: '/terms' },
  { title: '《免責聲明》', path: '/disclaimer' },
];

const Navigation = () => {
  const navigate = useNavigate();
  const handleBack = () => navigate('/landing');
  return <NavigationBar title="會員註冊" leftButtonHandler={handleBack} />;
};

function TwmPhoneNumberButton({ phoneNumberData, setPhoneNumber }) {
  const [showAlert, setShowAlert] = useState(false);
  const handleFillPhoneNumber = () => {
    setPhoneNumber(phoneNumberData);
    setShowAlert(false);
  };

  return (
    <>
      <StyledButton
        variant="contained"
        disableElevation
        sx={{
          position: 'fixed',
          top: '90px',
          right: '24px',
          fontSize: '13px',
          padding: '6px 10px',
          borderRadius: '4px',
        }}
        onClick={() => setShowAlert(true)}>
        填入台灣大哥大門號
      </StyledButton>
      <Dialog
        showDialog={showAlert}
        setShowDialog={setShowAlert}
        title="同意自動帶入"
        message="您是否同意自動帶入台灣大哥大門號？"
        actionLabel="同意"
        actionHandler={handleFillPhoneNumber}
        secondaryActionLabel="取消"
        secondaryActionHandler={() => setShowAlert(false)}
      />
    </>
  );
}

function InputSection({
  phoneNumber,
  setPhoneNumber,
  email,
  setEmail,
  password,
  setPassword,
  passwordConfirm,
  setPasswordConfirm,
}) {
  const inputInfo = [
    {
      inputLabel: '手機號碼',
      placeholder: '輸入您的手機號碼',
      id: 'phoneNumber',
      background: '#FFF',
      type: '',
      value: phoneNumber,
      setState: setPhoneNumber,
    },
    {
      inputLabel: 'Email',
      placeholder: 'example@gmail.com',
      id: 'email',
      background: '#FFF',
      type: '',
      value: email,
      setState: setEmail,
    },
    {
      inputLabel: '密碼設定',
      placeholder: '設定最少8位英數字的密碼',
      id: 'password',
      background: '#FFF',
      type: 'password',
      value: password,
      setState: setPassword,
    },
    {
      inputLabel: '密碼確認',
      placeholder: '再次輸入密碼',
      id: 'passwordConfirm',
      background: '#FFF',
      type: 'password',
      value: passwordConfirm,
      setState: setPasswordConfirm,
    },
  ];
  return (
    <Stack spacing={4} pt="16px">
      {inputInfo.map((input, index) => {
        const {
          inputLabel,
          placeholder,
          id,
          background,
          type,
          value,
          setState,
        } = input;
        return (
          <TextInput
            key={index}
            inputLabel={inputLabel}
            placeholder={placeholder}
            id={id}
            background={background}
            type={type}
            value={value}
            setState={setState}
          />
        );
      })}
    </Stack>
  );
}

function SignUpCheckBox({ isChecked, onChange, state }) {
  const navigate = useNavigate();

  function handleLinkClick(path) {
    secureLocalStorage.setItem('signUpData', {
      phoneNumber: state.phoneNumber,
      email: state.email,
      password: state.password,
      passwordConfirm: state.passwordConfirm,
    });
    navigate(path, { state: { previousPage: '/signUp' } });
  }

  return (
    <Paper sx={sxBottomCheckBoxBar} square elevation={0}>
      <FormControlLabel
        sx={{ marginRight: '0px' }}
        control={
          <Checkbox checked={isChecked} onChange={onChange} size="medium" />
        }
        label={
          <Box>
            <Label fontSize="15px">
              我是本國人且已成年，已詳閱並同意以下條款
            </Label>
            <Label fontSize="15px">
              {terms.map((term) => {
                return (
                  <Link
                    key={term.title}
                    onClick={() => handleLinkClick(term.path)}
                    underline="none">
                    {term.title}
                  </Link>
                );
              })}
            </Label>
          </Box>
        }
      />
    </Paper>
  );
}

const BottomButton = ({ isChecked, onClick }) => {
  return (
    <BottomPanel noShadow>
      <StyledButton
        variant="contained"
        fullWidth
        disabled={!isChecked}
        disableElevation
        onClick={onClick}>
        註冊會員
      </StyledButton>
    </BottomPanel>
  );
};

export default function SignUpPage() {
  const { setLoading, setUser } = useContext(UserContext);
  const { state } = useLocation();
  let signUpData;
  if (state?.previousPage === '/landing') {
    secureLocalStorage.removeItem('signUpData');
  } else if (
    ['/privacy', '/terms', '/disclaimer'].includes(state?.previousPage)
  ) {
    signUpData = secureLocalStorage.getItem('signUpData');
  }

  const [phoneNumber, setPhoneNumber] = useState(
    signUpData ? signUpData.phoneNumber : '',
  );
  const [email, setEmail] = useState(signUpData ? signUpData.email : '');
  const [password, setPassword] = useState(
    signUpData ? signUpData.password : '',
  );
  const [passwordConfirm, setPasswordConfirm] = useState(
    signUpData ? signUpData.passwordConfirm : '',
  );
  const phoneNumberData = secureLocalStorage.getItem('phoneNumber');
  const csAppUserData = secureLocalStorage.getItem('csAppUserData');
  const [isChecked, setIsChecked] = useState(false);
  const errorsRef = useRef({});
  const alertTitle = useRef('');
  const alertMessage = useRef('');
  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();

  const handleToggleCheck = () => {
    setIsChecked((isChecked) => !isChecked);
  };

  const handleSignUpClick = async () => {
    if (checkLoginInfo()) {
      setLoading(true);
      const payload = {
        data: csAppUserData || '',
        msisdn: phoneNumber,
        email: email,
        password: password,
        passwordConfirm: passwordConfirm,
      };
      const response = await UserAPI.postRegister(payload);
      setLoading(false);

      if (response?.error) {
        alertTitle.current = '建立會員失敗';
        alertMessage.current = response?.error.message;
        setShowAlert(true);
      } else if (response?.user) {
        setUser(response?.user);
        if (phoneNumberData) secureLocalStorage.removeItem('phoneNumber');
        secureLocalStorage.removeItem('signUpData');
        navigate('/phoneVerify', { state: { entry: '/signUp' } });
      }
    }
  };

  const checkLoginInfo = () => {
    let alert = null;
    if (!validator.isPhoneNumber(phoneNumber, errorsRef.current)) {
      alert = errorsRef.current.phone;
    } else if (!validator.isEmail(email, errorsRef.current)) {
      alert = errorsRef.current.email;
    } else if (!validator.isPassword(password, errorsRef.current)) {
      alert = errorsRef.current.password;
    } else if (
      !validator.isPasswordConfirm(password, passwordConfirm, errorsRef.current)
    ) {
      alert = errorsRef.current.passwordConfirm;
    }

    if (alert) {
      alertTitle.current = '會員註冊資料';
      alertMessage.current = alert;
      setShowAlert(true);
      return false;
    } else {
      return true;
    }
  };

  return (
    <Box sx={sxContainer}>
      <Navigation />
      {phoneNumberData && (
        <TwmPhoneNumberButton
          phoneNumberData={phoneNumberData}
          setPhoneNumber={setPhoneNumber}
        />
      )}
      <InputSection
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        passwordConfirm={passwordConfirm}
        setPasswordConfirm={setPasswordConfirm}
      />
      <SignUpCheckBox
        onChange={handleToggleCheck}
        isChecked={isChecked}
        state={{ phoneNumber, email, password, passwordConfirm }}
      />
      <BottomButton isChecked={isChecked} onClick={handleSignUpClick} />
      <Dialog
        showDialog={showAlert}
        setShowDialog={setShowAlert}
        title={alertTitle.current}
        message={alertMessage.current}
        actionLabel="確定"
        actionHandler={() => setShowAlert(false)}
      />
    </Box>
  );
}
