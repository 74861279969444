import { Box, Button, Grid } from "@mui/material";
import { styled } from "@mui/system";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useWebSocket from "react-use-websocket";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { getWebsocketToken } from "../../apis/configs/axiosConfigs";
import { ProductAPI } from "../../apis/ProductAPI";
import Dialog from "../../components/Dialog";
import Icon from "../../components/Icon";
import { SubTitleLabel, TitleLabel } from "../../components/Label";
import CoinPriceCell from "../../components/market/CoinPriceCell";
import { UserContext } from "../../UserContext";
import { notifyLoginFail } from "../../utils/WebViewCallback";
import "./slick-dot.css";

export default function MarketPage() {
    const { setLoading, user } = useContext(UserContext);
    const [products, setProducts] = useState([]);
    const [realtimePrices, setRealtimePrices] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const alertTitle = useRef("");
    const alertMessage = useRef("");
    const initData = useRef(false);
    const navigate = useNavigate();
    const { lastMessage } = useWebSocket(process.env.REACT_APP_CEX_PRICE_WS, {
        share: true,
        shouldReconnect: () => false,
        queryParams: {
            token: getWebsocketToken(),
        },
    });

    useEffect(() => {
        async function fetchProductData() {
            setLoading(true);
            const products = await ProductAPI.getProducts();
            setProducts(products);
            setLoading(false);
        }

        if (initData.current === false) {
            initData.current = true;
            fetchProductData();
        }

        if (lastMessage) {
            const data = JSON.parse(lastMessage?.data);
            if (data["id"] && data["status"]) {
                return;
            }

            const prices = data;
            setRealtimePrices(prices);
        }
    }, [products, lastMessage, setLoading]);

    const handleLoginFailAlertDismiss = () => {
        setShowAlert(false);
        notifyLoginFail();
    };

    const coinPriceList = () => {
        return (
            <>
                <TitleLabel marginBottom='8px'>市場行情</TitleLabel>
                <Box
                    display='flex'
                    justifyContent='space-between'
                    sx={{
                        marginLeft: "16px",
                        marginRight: "16px",
                        marginBottom: "8px",
                    }}
                >
                    <SubTitleLabel>虛擬貨幣</SubTitleLabel>
                    <SubTitleLabel marginLeft='60px'>
                        最新價格(TWD)
                    </SubTitleLabel>
                    <SubTitleLabel>24h漲跌%</SubTitleLabel>
                </Box>

                {products.map((product, index) => (
                    <CoinPriceCell
                        key={index}
                        product={product}
                        realtimePrices={realtimePrices}
                        onClick={() => {
                            navigate(`/market/${product.id}`);
                        }}
                    />
                ))}
            </>
        );
    };

    const RoundButton = ({ children, startIcon }) => {
        return (
            <Button
                variant='contained'
                disableElevation
                fullWidth
                sx={{ height: "48px" }}
                style={{
                    borderRadius: "24px",
                    backgroundColor: "#D9D9D9",
                    fontSize: "15px",
                }}
                startIcon={startIcon}
            >
                {children}
            </Button>
        );
    };

    const purchaseButtons = () => {
        return (
            <Box sx={{ flexGrow: 1 }}>
                <Grid
                    container
                    spacing={2}
                    columns={16}
                    marginBottom='16px'
                >
                    <Grid
                        item
                        xs={8}
                    >
                        <RoundButton
                            startIcon={
                                <Icon
                                    src='../../images/img_stacking.png'
                                    width='25px'
                                />
                            }
                        >
                            加密貨幣定存
                        </RoundButton>
                    </Grid>
                    <Grid
                        item
                        xs={8}
                    >
                        <RoundButton
                            startIcon={
                                <Icon
                                    src='../../images/img_regular_purchase.png'
                                    width='25px'
                                />
                            }
                        >
                            定期定額購買
                        </RoundButton>
                    </Grid>
                </Grid>
            </Box>
        );
    };

    const Image = styled("img")(() => ({
        width: "100%",
        height: "100%",
        borderRadius: "12px",
    }));

    function Banner({ image }) {
        return (
            <Box
                sx={{
                    height: "190px",
                    marginLeft: "16px",
                    marginRight: "16px",
                }}
            >
                <Image src={`/images/${image}`} />
            </Box>
        );
    }

    const promotionBannerSection = () => {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 800,
            autoplaySpeed: 5000,
            dotsClass: "custom_dot",
        };

        const images = [
            "banner1.jpg",
            "banner2.jpg",
            "banner3.jpg",
            "banner4.jpg",
        ];

        return (
            <Box
                sx={{
                    marginTop: "16px",
                    marginBottom: "16px",
                    overflow: "hidden",
                }}
            >
                <Slider {...settings}>
                    {images.map((image, index) => (
                        <Banner
                            key={index}
                            image={image}
                        />
                    ))}
                </Slider>
            </Box>
        );
    };

    return (
        <>
            {promotionBannerSection()}

            <Box padding='16px'>
                {/* {purchaseButtons()} */}
                {coinPriceList()}
            </Box>

            <Dialog
                showDialog={showAlert}
                setShowDialog={setShowAlert}
                title={alertTitle.current}
                message={alertMessage.current}
                actionLabel='確定'
                actionHandler={handleLoginFailAlertDismiss}
                closeHandler={handleLoginFailAlertDismiss}
            />
        </>
    );
}
