import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Stack, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const ItemBox = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'start',
  color: theme.palette.text.primary,
  height: 42,
  lineHeight: '42px',
  paddingLeft: '16px',
  border: '1px solid #CFCFCF',
  borderRadius: '8px',
  boxShadow: '0px 3px 3px 0px rgba(0, 0, 0, 0.05)',
}));


export default function RadioButtonsGroup({ id, title, options, name, selectedValue={ value: '', remark: '' }, showError, onChange}) {

  const handleChange = (e) => {
    const newValue = e.target.value;
    const newRemark = options.find(option => option.value === newValue)?.textbox ? selectedValue.remark : '';
    onChange({ value: newValue, remark: newRemark });
  };

  const handleRemarkChange = (e) => {
    const updatedRemark = e.target.value;
    onChange({ value: selectedValue.value, remark: updatedRemark });
  };

  const currentOption = options.find(option => option.value === selectedValue.value);
  
  return (
    <FormControl error={showError}>
      <RadioGroup 
        aria-labelledby={id} 
        value={selectedValue.value || ''}
        name={name}
        onChange={handleChange}
      >
        <Stack spacing="16px">
          {options.map((item) => {
            return (
              <ItemBox key={item.value}>
                <FormControlLabel
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              </ItemBox>
            );
          })}
          {currentOption?.textbox && (
            <TextField 
              variant="standard" 
              style={{marginTop:'8px'}}
              label={currentOption.annotation} 
              value={selectedValue.remark || ''}
              onChange={handleRemarkChange}
            />
          )}
        </Stack>
      </RadioGroup>
      <FormHelperText id={`${name}-error-text`}>{showError && `請填寫: ${title}`}</FormHelperText>
    </FormControl>
  );
}
