import { Typography } from "@mui/material";
import { styled } from "@mui/system";

const getFontWeight = (theme, fontWeight) => {
    if (fontWeight === "light") {
        return theme.typography.fontWeightLight;
    } else if (fontWeight === "medium") {
        return theme.typography.fontWeightMedium;
    } else if (fontWeight === "bold") {
        return theme.typography.fontWeightBold;
    } else {
        return theme.typography.fontWeightRegular;
    }
};

const Label = styled(Typography, {
    shouldForwardProp: (prop) =>
        prop !== "color" && prop !== "fontSize" && prop !== "fontWeight",
})(({ theme, color, fontSize, fontWeight }) => ({
    color: color ? color : theme.palette.text.main,
    fontSize: fontSize ? fontSize : "16px",
    fontWeight: getFontWeight(theme, fontWeight ? fontWeight : "regular"),
}));

const TitleLabel = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "fontSize" && prop !== "fontWeight",
})(({ theme, fontSize, fontWeight }) => ({
    color: theme.palette.text.main,
    fontSize: fontSize ? fontSize : "19px",
    fontWeight: getFontWeight(theme, fontWeight ? fontWeight : "bold"),
}));

const SubTitleLabel = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "fontSize" && prop !== "fontWeight",
})(({ theme, fontSize, fontWeight }) => ({
    color: theme.palette.text.secondary,
    fontSize: fontSize ? fontSize : "13px",
    fontWeight: getFontWeight(theme, fontWeight ? fontWeight : "medium"),
}));

const SuccessLabel = styled(Label)({
    fontSize: "11px",
    fontWeight: "medium",
    color: "#21BF73",
});

const CancelLabel = styled(Label)({
    fontSize: "11px",
    fontWeight: "medium",
    color: "#303030",
});

const PendingLabel = styled(Label)({
    fontSize: "11px",
    fontWeight: "medium",
    color: "#FFB741",
});

export {
    Label,
    TitleLabel,
    SubTitleLabel,
    SuccessLabel,
    CancelLabel,
    PendingLabel,
};
