import { useContext } from "react";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import NavigationBar from "../../components/NavigationBar";
import { navigationBarHeight } from "../../components/Layout";
import BottomPanel from "../../components/BottomPanel";
import { UserContext } from "../../UserContext";
import ResultTemplate from "./components/ResultTemplate";

const TradeButton = styled(Button)(({ theme }) => ({
    color: "#FFF",
    fontSize: "17px",
    fontWeight: "bold",
    borderRadius: "9px",
}));

export default function ChangePhoneNumberSucceedPage() {
    const { user } = useContext(UserContext);
    const { setTabIndex } = useContext(UserContext);
    const navigate = useNavigate();

    const handleBack = () => {
        setTabIndex(0);
        navigate("/");
    };

    return (
        <Box sx={{ pt: `${navigationBarHeight}px` }}>
            <NavigationBar
                title='更換成功'
                hideLeftButton={true}
            />
            <Box>
                <ResultTemplate
                    title='手機門號更換成功'
                    desc={`您的手機門號已成功更換為 ${user?.phoneNumber}，請點擊下方確認進入OP 加密資產存摺服務，謝謝`}
                    succeed={true}
                />
            </Box>
            <BottomPanel>
                <TradeButton
                    variant='contained'
                    fullWidth
                    disableElevation
                    onClick={handleBack}
                >
                    確認
                </TradeButton>
            </BottomPanel>
        </Box>
    );
}
