import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { React, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserAPI } from '../../apis/UserAPI';
import BottomPanel from '../../components/BottomPanel';
import Dialog from '../../components/Dialog';
import { Label } from '../../components/Label';
import { navigationBarHeight } from '../../components/Layout';
import NavigationBar from '../../components/NavigationBar';
import StyledButton from '../../components/StyledButton';
import { UserContext } from '../../UserContext';

const sxContainer = {
  pt: `${navigationBarHeight}px`,
};

const ListItem = styled('li')(({ theme }) => ({
  color: '#000',
  fontSize: '15px',
  fontWeight: 'regular',
}));

const InputBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#FFF',
  width: '100%',
  height: '56px',
  borderRadius: '9px',
}));

const txtInputProps = {
  disableUnderline: true,
  style: {
    fontSize: '15px',
  },
};

const sxTextField = {
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: 'black',
  },
};

export default function BankAccountPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { user, setUser, setLoading, setTabIndex, setNavTitle } =
    useContext(UserContext);
  const bankCode = useRef(''); //user?.bankAccount?.bankCode;
  const bankBranchCode = useRef(''); //user?.bankAccount?.branchNumber;
  const accountNumber = user?.bankAccount?.accountNumber;
  const [showDialog, setShowDialog] = useState(false);
  const [fetchBankName, setFetchBankName] = useState('');
  const bankName = useRef(''); //user.bankAccount?.bankName;
  const bankBranchName = useRef(''); //user.bankAccount?.bankBranchName;
  const alertTitle = useRef(null);
  const alertMessage = useRef(null);
  const alertActionLabel = useRef(null);
  const accountCompleted = useRef(false);
  const tBankAccount = useRef(null);
  const originFrom = useRef(null);

  useEffect(() => {
    if (state?.superfrom === 'bankMenu') {
      tBankAccount.current = state?.tBankAccount;
      console.log('[return]bankCode:' + JSON.stringify(tBankAccount.current));
      setUser({
        ...user,
        bankAccount: {
          ...user.tBankAccount,
          bankCode: tBankAccount.current?.bankCode,
          branchNumber: tBankAccount.current?.branchNumber,
        },
      });
      originFrom.current = state?.originFrom;
    } else {
      tBankAccount.current = user?.bankAccount;
      originFrom.current = state?.from;
    }
    bankCode.current = tBankAccount.current?.bankCode
      ? tBankAccount.current?.bankCode
      : '';
    bankName.current = tBankAccount.current?.bankName
      ? tBankAccount.current?.bankName
      : '';
    bankBranchCode.current = tBankAccount.current?.branchNumber
      ? tBankAccount.current?.branchNumber
      : '';
    bankBranchName.current = tBankAccount.current?.bankBranchName
      ? tBankAccount.current?.bankBranchName
      : '';
  }, []);

  useEffect(() => {
    if (state?.from === '/bankStatus') {
      (async function () {
        const getBankLists = await UserAPI.getBankList();
        bankName.current = getBankLists.find(
          (bank) => bank.bankCode === user.bankAccount.bankCode,
        ).name;
        tBankAccount.current.bankName = bankName.current;
        setFetchBankName(bankName.current);
      })();
      (async function () {
        const getBankBranchLists = await UserAPI.getBankBranchList(
          user.bankAccount.bankCode,
        );

        bankBranchName.current = getBankBranchLists.find(
          (bankBranch) =>
            bankBranch.branchCode === user.bankAccount.branchNumber,
        ).name;
        tBankAccount.current.bankBranchName = bankBranchName.current;
        setFetchBankName(bankBranchName.current);
      })();
      originFrom.current = state?.originFrom;
    }
  }, []);

  const handleBack = () => {
    const pathsToBankAccount = [
      '/member',
      '/kycSucceed',
      '/bankStatus',
      '/asset',
    ];
    if (!pathsToBankAccount.includes(originFrom.current)) {
      setTabIndex(0);
      setNavTitle('OP加密資產存摺');
      navigate('/');
    } else {
      navigate(originFrom.current);
    }
  };

  const handleClickSelectBank = () => {
    navigate('/bankMenu', {
      state: {
        selected: bankCode.current,
        superfrom: 'bankAccount',
        tBankAccount: tBankAccount.current,
        originFrom: originFrom.current,
      },
    });
  };

  const handleClickSelectBankBranch = () => {
    if (!bankCode.current) {
      alertTitle.current = '設定銀行帳號';
      alertMessage.current = '請選擇銀行代碼';
      alertActionLabel.current = '確定';
      accountCompleted.current = false;
      setShowDialog(true);
      return;
    }

    let code = null;
    if (bankCode.current && bankBranchCode.current) {
      code = bankBranchCode.current;
    }
    navigate('/bankMenu', {
      state: {
        bankCode: bankCode.current,
        selected: code,
        superfrom: 'bankAccount',
        tBankAccount: tBankAccount.current,
        originFrom: originFrom.current,
      },
    });
  };

  const handleSetBankAccount = () => {
    const err = checkBankAccount();
    if (err) {
      alertTitle.current = '設定銀行帳號';
      alertMessage.current = err;
      alertActionLabel.current = '確定';
      accountCompleted.current = false;
    } else {
      alertTitle.current = '確認綁定帳號';
      alertMessage.current = `請確認是否綁定${bankName.current} ${accountNumber}作為您未來轉帳、提領的帳戶。`;
      alertActionLabel.current = '確認綁定';
      accountCompleted.current = true;
    }

    setShowDialog(true);
  };

  const handleDialogAction = async () => {
    if (accountCompleted.current) {
      setLoading(true);

      const payload = {
        bankCode: bankCode.current,
        branchNumber: bankBranchCode.current,
        accountNumber: accountNumber,
      };
      const responseData = await UserAPI.setBankAccount(payload);
      if (responseData) {
        console.log(responseData);
        setUser(responseData);
      }

      setLoading(false);
      navigate('/bankStatus');
    } else {
      setShowDialog(false);
    }
  };

  const checkBankAccount = () => {
    if (!bankCode.current) {
      return '請選擇銀行代碼';
    } else if (!bankBranchCode.current) {
      return '請選擇銀行分行代碼';
    } else if (!accountNumber) {
      return '請填寫銀行帳號';
    } else if (accountNumber.length < 12 || accountNumber.length > 16) {
      return '銀行帳號格式錯誤';
    }
    return null;
  };

  const navigation = () => {
    return (
      <NavigationBar title="綁定銀行帳號" leftButtonHandler={handleBack} />
    );
  };

  const bottomPanel = () => {
    return (
      <BottomPanel>
        <StyledButton
          variant="contained"
          fullWidth
          disableElevation
          onClick={handleSetBankAccount}>
          進行綁定
        </StyledButton>
      </BottomPanel>
    );
  };

  const description = () => {
    const items = [
      '請提供銀行存摺上所示之帳號及分行代碼，並確認戶名為本人所有',
      '請勿自行省略帳號內的分行代碼',
      '用戶僅能用綁定的銀行帳號進行入金和提領的操作',
      '我們會存入台幣1元驗證您帳號是否正確，驗證審核約需3個工作天',
    ];
    return (
      <Box
        sx={{
          marginLeft: '-16px',
          marginRight: '8px',
          marginBottom: '24px',
        }}>
        <ul>
          {items.map((item, index) => (
            <ListItem key={index}>{item}</ListItem>
          ))}
        </ul>
      </Box>
    );
  };

  const bankAccountFields = () => {
    return (
      <>
        {/* 銀行戶名 */}
        <Label
          fontSize="17px"
          fontWeight="bold"
          sx={{ marginLeft: '8px', marginBottom: '8px' }}>
          銀行戶名
        </Label>

        <InputBox
          sx={{
            padding: '16px',
            marginBottom: '24px',
            backgroundColor: '#E8E8E8',
          }}>
          {user?.chineseName}
        </InputBox>

        {/* 銀行代碼 */}
        <Label
          fontSize="17px"
          fontWeight="bold"
          sx={{ marginLeft: '8px', marginBottom: '8px' }}>
          銀行代碼
        </Label>

        <InputBox
          display="flex"
          alignItems="center"
          sx={{ padding: '16px', marginBottom: '24px' }}
          onClick={handleClickSelectBank}>
          <TextField
            variant="standard"
            InputProps={txtInputProps}
            hiddenLabel
            autoComplete="off"
            fullWidth
            inputProps={{ min: 0, style: { textAlign: 'left' } }}
            placeholder={'請選擇銀行代碼'}
            sx={sxTextField}
            value={
              bankCode.current
                ? `${bankCode.current} ${bankName.current || ''}`.trim()
                : ''
            }
            disabled
          />
          <ArrowDropDownIcon />
        </InputBox>

        {/* 銀行分行代碼 */}
        <Label
          fontSize="17px"
          fontWeight="bold"
          sx={{ marginLeft: '8px', marginBottom: '8px' }}>
          銀行分行代碼
        </Label>

        <InputBox
          display="flex"
          alignItems="center"
          sx={{ padding: '16px', marginBottom: '24px' }}
          onClick={handleClickSelectBankBranch}>
          <TextField
            variant="standard"
            InputProps={txtInputProps}
            hiddenLabel
            autoComplete="off"
            fullWidth
            inputProps={{ min: 0, style: { textAlign: 'left' } }}
            placeholder="請選擇銀行分行代碼"
            sx={sxTextField}
            value={
              bankBranchCode.current
                ? `${bankBranchCode.current} ${
                    bankBranchName.current || ''
                  }`.trim()
                : ''
            }
            disabled
          />
          <ArrowDropDownIcon />
        </InputBox>

        {/* 銀行帳號 */}
        <Label
          fontSize="17px"
          fontWeight="bold"
          sx={{ marginLeft: '8px', marginBottom: '8px' }}>
          銀行帳號
        </Label>

        <InputBox alignItems="center" sx={{ padding: '16px' }}>
          <TextField
            type="number"
            variant="standard"
            InputProps={txtInputProps}
            hiddenLabel
            autoComplete="off"
            fullWidth
            inputProps={{ min: 0, style: { textAlign: 'left' } }}
            placeholder="請填寫銀行帳號"
            sx={sxTextField}
            onChange={(e) => {
              setUser({
                ...user,
                bankAccount: {
                  ...tBankAccount.current,
                  accountNumber: e.target.value,
                },
              });
            }}
            value={accountNumber ? accountNumber : ''}
          />
        </InputBox>
      </>
    );
  };

  return (
    <>
      <Box sx={sxContainer}>
        {navigation() /* navigation bar */}
        <Box padding="16px" marginBottom="50px">
          {description()}
          {bankAccountFields()}
        </Box>
        {bottomPanel()}
      </Box>

      <Dialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        title={alertTitle.current}
        message={alertMessage.current}
        actionLabel={alertActionLabel.current}
        actionHandler={handleDialogAction}
      />
    </>
  );
}
