import React from 'react';
import { Box } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Label } from '../Label';

export default function MemberCenterCell({ item }) {
  const handleClick = () => {
    if (item?.handler) {
      item.handler();
    }
  };

  return (
    <Box
      alignItems="center"
      justifyContent="space-between"
      height="72px"
      display="flex"
      onClick={handleClick}>
      <Box display="flex" alignItems="center">
        {item.icon && item.icon}
        <Label marginLeft="16px" fontSize="18px">
          {item.text}
        </Label>
      </Box>

      <Box display="flex" alignItems="center">
        {item.state && (
          <Label fontSize="18px" marginRight="10px">
            {item.state}
          </Label>
        )}

        {!item?.hideIndicator && (
          <NavigateNextIcon style={{ color: 'silver' }} />
        )}
      </Box>
    </Box>
  );
}
