import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";

import Panel from "../Panel";
import Icon from "../Icon";
import { Box, Typography } from "@mui/material";
import { PriceNumberFormat } from "../../utils/CoinNumberFormat";

const CoinSymbolLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.main,
    fontSize: "17px",
    fontWeight: "medium",
}));

const CoinNameLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: "11px",
}));

const CoinPriceBox = styled(Box)({
    width: "100px",
});

const CoinPriceLabel = styled(Typography)({
    color: "#000",
    fontSize: "17px",
    left: 90,
});

const PercentageBox = styled(Box)({
    borderRadius: "6px",
    width: "72px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});

const PercentageLabel = styled(Typography)({
    color: "#FFF",
    fontSize: "15px",
});

const sxGreen = {
    backgroundColor: "#21BF73",
};

const sxRed = {
    backgroundColor: "#FF5656",
};

function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export default function CoinPriceCell({ product, realtimePrices, onClick }) {
    const [price, setPrice] = useState(0);
    const [fluctuation, setFluctuation] = useState(0);

    useEffect(() => {
        if (realtimePrices) {
            const priceData = realtimePrices[product.id]["balance"];
            setTimeout(function () {
                setPrice(priceData.buy_price);

                setFluctuation(
                    Number(priceData.fluctuation.toString().substr(0, 4))
                );
            }, randomIntFromInterval(0, 8000));
        } else if (product) {
            const priceData = product.realtimePrice.balance;
            setPrice(priceData.buyPrice);
            setFluctuation(
                Number(priceData.fluctuation.toString().substr(0, 4))
            );
        }
    }, [product, realtimePrices]);

    return (
        <Panel gutterBottom>
            <Box
                alignItems='center'
                justifyContent='space-between'
                height='72px'
                display='flex'
                padding='16px'
                onClick={onClick}
            >
                <Icon src={`/images/${product.baseCurrency.id}.png`} />

                <Box marginLeft='-10px'>
                    <CoinSymbolLabel>{product.baseCurrency.id}</CoinSymbolLabel>
                    <CoinNameLabel>{product.baseCurrency.name}</CoinNameLabel>
                </Box>

                <CoinPriceBox>
                    <CoinPriceLabel align='right'>
                        <PriceNumberFormat value={price} />
                    </CoinPriceLabel>
                </CoinPriceBox>

                <PercentageBox sx={fluctuation >= 0 ? sxGreen : sxRed}>
                    <PercentageLabel>
                        {fluctuation > 0 ? "+" + fluctuation : fluctuation}%
                    </PercentageLabel>
                </PercentageBox>
            </Box>
        </Panel>
    );
}
