import { styled } from "@mui/system";
import { Box } from "@mui/material";

const StatusBox = styled(Box)({
    borderRadius: "6px",
    width: "48px",
    height: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});

const SuccessStatusBox = styled(StatusBox)({
    backgroundColor: "#D7FFE4",
});

const CancelStatusBox = styled(StatusBox)({
    backgroundColor: "#E6E6E6",
});

const PendingStatusBox = styled(StatusBox)({
    backgroundColor: "#FFF4D1",
});

export { SuccessStatusBox, CancelStatusBox, PendingStatusBox };
