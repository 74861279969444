import { styled } from "@mui/system";
import { Button } from "@mui/material";

const StyledButton = styled(Button)(({ theme }) => ({
    color: "#FFF",
    fontSize: "17px",
    fontWeight: "bold",
    borderRadius: "9px",
}));

export default StyledButton;
