import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import NavigationBar from '../../components/NavigationBar';
import TextInput from './components/TextInput';
import BottomPanel from '../../components/BottomPanel';
import StyledButton from '../../components/StyledButton';
import Dialog from '../../components/Dialog';
import { navigationBarHeight } from '../../components/Layout';
import { UserAPI } from '../../apis/UserAPI';
import validator from './validator';

export default function ResetPasswordPhoneNumberPage() {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [errors, setErrors] = useState({});
  const errorMessage = useRef();

  const nextStep = async () => {
    const isValid = checkPhoneNumberValidation();
    if (isValid) {
      const result = await forgetPassword();
      if (result) {
        let phoneNumber = result?.phoneNumber;
        navigate('/resetPassword/otp', {
          state: { entry: '/resetPassword/phoneNumber', data: { phoneNumber } },
        });
      }
    }
  };

  const checkPhoneNumberValidation = () => {
    if (!validator.isPhoneNumber(phoneNumber, errors)) {
      setShowDialog(true);
      errorMessage.current = errors.phone;
      return false;
    } else {
      return true;
    }
  };

  const forgetPassword = async () => {
    const payload = { phoneNumber };
    const response = await UserAPI.forgetPassword(payload);
    if (response?.error) {
      setShowDialog(true);
      errorMessage.current = response?.error?.message;
      return false;
    } else {
      return true;
    }
  };

  return (
    <Box sx={{ p: `${navigationBarHeight}px 24px 0`, height: '75vh' }}>
      <NavigationBar
        title="請輸入手機號碼"
        leftButtonHandler={() => navigate('/signIn')}
      />
      <Stack spacing={4} pt="16px">
        <TextInput
          id="phoneNumber"
          inputLabel="手機號碼"
          placeholder="0911111111"
          type=""
          background="#FFF"
          value={phoneNumber}
          setState={setPhoneNumber}
        />
      </Stack>
      <BottomPanel noShadow>
        <StyledButton
          variant="contained"
          fullWidth
          disabled={false}
          disableElevation
          onClick={nextStep}>
          下一步
        </StyledButton>
      </BottomPanel>
      <Dialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        title="手機號碼錯誤"
        message={errorMessage.current}
        actionLabel="確定"
        actionHandler={() => setShowDialog(false)}
      />
    </Box>
  );
}
