import { Box, Button, Divider, Snackbar, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { React, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BottomPanel from '../../components/BottomPanel';
import Dialog from '../../components/Dialog';
import { Label, SubTitleLabel } from '../../components/Label';
import { navigationBarHeight } from '../../components/Layout';
import NavigationBar from '../../components/NavigationBar';
import Panel from '../../components/Panel';
import { UserContext } from '../../UserContext';
import { getBankName } from '../../utils/BankCode';

const AuthorizeButton = styled(Button)(({ theme }) => ({
  color: '#FFF',
  fontSize: '17px',
  fontWeight: 'bold',
  borderRadius: '9px',
}));

const sxContainer = {
  pt: `${navigationBarHeight}px`,
};

const DescriptionLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.main,
  fontSize: '15px',
  fontWeight: '400',
}));

export default function BankDepositPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [showAlert, setShowAlert] = useState(false);
  const [bankName, setBankName] = useState('');
  const [openCopyMessage, setOpenCopyMessage] = useState('');
  const { user } = useContext(UserContext);
  const alertTitle = useRef('');
  const alertMessage = useRef('');
  const bankCode = user.bankAccount.bankCode;
  const price = location?.state?.price;
  const productId = location?.state?.productId;
  const paymentValue = location?.state?.paymentValue;
  const invoice = location?.state?.invoice;

  useEffect(() => {
    const _getBankName = async () => {
      const _bankName = await getBankName(bankCode);
      setBankName(_bankName);
    };
    _getBankName();
  }, [bankCode]);

  const handleBack = () => {
    if (productId) {
      navigate(`/buy/${productId}`, { state: { price, paymentValue, invoice } });
    } else {
      navigate(-1)
    }
  };

  const handleAccountIdCopy = async () => {
    navigator.clipboard.writeText(user?.virtualAccountNumber);
    setOpenCopyMessage((prev) => !prev);
  };

  const navigation = () => {
    return <NavigationBar title="台幣儲值" leftButtonHandler={handleBack} />;
  };

  const bottomPanel = () => {
    return (
      <BottomPanel>
        <AuthorizeButton
          variant="contained"
          fullWidth
          disableElevation
          onClick={handleAccountIdCopy}>
          複製匯款帳號
        </AuthorizeButton>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={openCopyMessage}
          autoHideDuration={5000}
          onClose={handleAccountIdCopy}
          message="匯款帳號已複製"
          key={'bottomCenter'}
          ContentProps={{
            sx: {
              display: 'block',
              textAlign: 'center',
            },
          }}
        />
      </BottomPanel>
    );
  };

  const bankDepositDesc = () => {
    return (
      <Box marginTop="10px" marginBottom="10px">
        <DescriptionLabel>
          請使用「綁定銀行帳號」轉帳至以下您專屬的帳號進行帳戶儲值
        </DescriptionLabel>
      </Box>
    );
  };

  const bankDepositInfo = () => {
    return (
      <>
        <Panel>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="16px"
            paddingTop="22px">
            <Label fontSize="15px">銀行代碼</Label>
            <Label fontSize="15px">012 台北富邦銀行</Label>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="16px"
            paddingBottom="22px">
            <Label fontSize="15px">匯款帳號</Label>
            <Label fontSize="15px">{user?.virtualAccountNumber}</Label>
          </Box>
        </Panel>
      </>
    );
  };

  const bankDepositCautions = () => {
    return (
      <Box marginTop="20px" marginBottom="90px">
        <SubTitleLabel>
          注意事項：
          <br />
          1.使用本服務時，請先確認您是從台灣大客服內使用OP加密資產存摺的服務，若您使用官方發布之外之應用程式造成任何損失，本服務的提供者不承擔任何責任。
          <br />
          2.請使用「綁定銀行帳號」於網路ATM、實體ATM、網路銀行，將款項轉入以上帳號；請勿使用臨櫃匯款、ATM現金存款。
          <br />
          3.儲值完成無法更改或取消。
        </SubTitleLabel>
      </Box>
    );
  };

  return (
    <>
      <Box sx={sxContainer}>
        {navigation() /* navigation bar */}
        <Box padding="16px">
          {bankDepositDesc()}
          {bankDepositInfo()}
          <Divider
            sx={{
              ml: '-16px',
              mr: '-16px',
            }}
          />
          {bankDepositCautions()}
        </Box>
        {bottomPanel() /* 按鈕 */}
      </Box>

      <Dialog
        showDialog={showAlert}
        setShowDialog={setShowAlert}
        title={alertTitle.current}
        message={alertMessage.current}
        actionLabel="確定"
        actionHandler={() => setShowAlert(false)}
      />
    </>
  );
}
