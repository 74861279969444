const disclaimer = {
  title:'免責聲明',
  description:'',
  sections:[
    {
      title:'',
      description:'',
      sectionList:{
        items:[
          {
            item:
              'OP加密資產存摺服務、本網站以及此處提供的內容可能包含某些尚未被辨識的問題。OP加密資產存摺服務、本網站及內容是以其「現有」狀態而提供。建議您謹慎地使用OP加密資產存摺服務、本網站以及內容，並為必要之查證和判斷，且您不可以完全倚賴OP加密資產存摺服務、本網站以及內容及其附隨的指示或資料。本公司不保證將永久提供OP加密資產存摺服務、本網站和內容（包括但不限於OP加密資產存摺服務及本網站的全部或任何特定期間的特定功能）。對於任何OP加密資產存摺服務、本網站及內容的事項、修改或改善，本公司不做出任何保證。本公司在此特別聲明不提供任何默示的保證。',
          },{
            item:
              '無論是自本公司、OP加密資產存摺服務、本網站或內容所獲取的任何口頭或書面的建議或資訊，都無法創造未在這裡明示的任何保證。您應對您所有交易或持有數位貨幣一事自行負擔全部責任。',
          },{
            item:
              '上述免責聲明應在法律允許的最大範圍下適用，並且在本使用條款終止或失效後，或在用戶終止使用OP加密資產存摺服務和本網站或該使用失效後，仍繼續適用。',
          },{
            item:
              '合作銀行僅係受OP加密資產存摺委託，處理交易方的交易預備餘額間的資金移轉以及保管交易預備餘額中的法定貨幣。合作銀行並未替OP加密資產存摺、OP加密資產存摺服務背書或保證，且合作銀行不負責保管數位資產與辨識數位資產之真偽。'
          }
        ],
        orderBy:'ordinalNumber'
      },
      additionInfo:''
    }
  ]
}

export default disclaimer;
