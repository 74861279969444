import { api } from "./configs/axiosConfigs";

export const FAQsAPI = {
    getFAQLists: async function () {
        const response = await api.request({
            url: "faqs/",
            method: "GET",
        });

        return response.data;
    }
};