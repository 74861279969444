import { api } from "./configs/axiosConfigs";

//使用者取得edd選項及審查單號
export const EddAPI = {
  getEddOptions: async function(){
    try{
      const response =await api.request({
        url:"/edd-options", 
        method:"GET",
      });
      return response.data;
    }catch(error) {
      return error.response.data;
    }
  },
  //edd建單(Content-Type為multipart/form-data)
  createEdd: async function(formData){
    try{
      const response = await api.request({
        url:"/edd-form",
        method:"POST",
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return [response.status,response.data];
    }catch(error) {
      return [error.response.status,error.response.data];
    }
  }
}