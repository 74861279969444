import { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import NavigationBar from '../../components/NavigationBar';
import TextInput from './components/TextInput';
import BottomPanel from '../../components/BottomPanel';
import StyledButton from '../../components/StyledButton';
import Dialog from '../../components/Dialog';
import { navigationBarHeight } from '../../components/Layout';
import validator from './validator';
import { UserAPI } from '../../apis/UserAPI';

export default function ResetPasswordIdentityPage() {
  const navigate = useNavigate();
  const [identity, setIdentity] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [errors] = useState({})
  const { state } = useLocation();
  const errorMessage = useRef();

  const nextStep = async () => {
    const isValid = checkIdentityValidation()
    if (isValid) {
      const result = await verifyIdentityNumber();
      if (result) {
        navigate('/resetPassword', {
          state: { entry: '/resetPassword/identity'}
        })
      }
    }
  }

  const checkIdentityValidation = () => {
    if (!validator.isUserId(identity, errors)) {
      setShowDialog(true);
      errorMessage.current = errors.id;
      return false
    } else {
      return true
    }
  }

  const verifyIdentityNumber = async () => {
    const payload = { identityNumber: identity}
    const response = await UserAPI.forgetPasswordVerifyIdentityNumber(payload)
    if (response?.error) {
      setShowDialog(true);
      errorMessage.current = response?.error?.message;
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    if (state?.entry !== "/resetPassword/otp") {
      navigate("/signIn")
    }
  }, [navigate, state])

  return (
    <Box sx={{ p: `${navigationBarHeight}px 24px 0`, height: '75vh' }}>
      <NavigationBar
        title="請輸入您的身分證字號"
        leftButtonHandler={() => navigate('/signIn')}
      />
      <Stack spacing={4} pt="16px">
        <TextInput
          id="identity"
          inputLabel="身分證字號"
          placeholder="身分證字號"
          type=""
          background="#FFF"
          value={identity}
          setState={setIdentity}
        />
      </Stack>
      <BottomPanel noShadow>
        <StyledButton
          variant="contained"
          fullWidth
          disabled={false}
          disableElevation
          onClick={nextStep}>
          下一步
        </StyledButton>
      </BottomPanel>
      <Dialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        title="身份證字號錯誤"
        message={errorMessage.current}
        actionLabel="確定"
        actionHandler={() => setShowDialog(false)}
      />
    </Box>
  );
}
