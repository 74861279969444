import React from 'react';
import { Paper, Box } from '@mui/material';

const sxBottomBar = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: '80px',
  padding: '16px',
  zIndex: '1200',
};

export default function BottomPanel({ children, noShadow }) {
  return (
    <Paper sx={sxBottomBar} elevation={noShadow ? 0 : 3}>
      <Box display="flex" height="100%">
        {children}
      </Box>
    </Paper>
  );
}
