import { styled } from "@mui/system";
import { Box } from "@mui/material";

const Separator = styled(Box)(({ theme }) => ({
    backgroundColor: "#ECECEC",
    width: "100%",
    height: "1px",
}));

export default Separator;
