import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, styled } from "@mui/material";
import NavigationBar from "../../components/NavigationBar";
import { navigationBarHeight } from "../../components/Layout";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { TitleLabel, Label } from "../../components/Label";
import { useEffectOnce } from "../../utils/UseEffectOnce";
import { FAQsAPI } from "../../apis/FAQsAPI";

const sxContainer = {
    pt: `${navigationBarHeight}px`,
    margin: "16px",
};

const Accordion = styled((props) => (
    <MuiAccordion
        disableGutters
        elevation={0}
        square
        {...props}
    />
))(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:before": {
        display: "none",
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={
            <ArrowDropDownIcon sx={{ fontSize: "2rem", color: "black" }} />
        }
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: "rgba(255, 255, 255, .05)",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(-180deg)",
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
    backgroundColor: "rgba(0, 0, 0, .09)",
}));

const Navigation = () => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <NavigationBar
            title='常見問題'
            leftButtonHandler={handleBack}
        />
    );
};

const DropDown = ({ FAQ, index }) => {
    const [expanded, setExpanded] = useState("panel0");
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const { question, answer } = FAQ;

    return (
        <Accordion
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
        >
            <AccordionSummary
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
            >
                <TitleLabel fontSize='16px'>{question}</TitleLabel>
            </AccordionSummary>
            <AccordionDetails>
                <Label fontSize='13px'>{answer}</Label>
            </AccordionDetails>
        </Accordion>
    );
};

const FAQs = () => {
    const [FAQLists, setFAQLists] = useState([]);
    useEffectOnce(() => {
        const getFAQLists = async () => {
            const response = await FAQsAPI.getFAQLists();
            setFAQLists(response);
        };
        getFAQLists();
    });

    return (
        <>
            {FAQLists.map((FAQ, index) => (
                <DropDown
                    FAQ={FAQ}
                    key={FAQ.question}
                    index={index}
                />
            ))}
        </>
    );
};

export default function FAQPage() {
    return (
        <Box sx={sxContainer}>
            <Navigation />
            <FAQs />
        </Box>
    );
}
