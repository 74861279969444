import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import { keyframes } from "@mui/system";

const DigitLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.main,
    fontSize: "24px",
    fontWeight: "medium",
    textAlign: "center",
}));

const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

//顯示框符號動畫
const AnimatedDigitLabel = styled(Typography)(({ theme }) => ({
    color: "#FF8F08",
    fontSize: "32px",
    fontWeight: 'lighter',
    lineHeight:'1',
    animation: `${blink} 1s linear infinite`,
}));

export default function DigitLabelBox({ value, animated = false,isEditing = false}) {
    return (
        <Box
            boxShadow='0px 0px 4px 0px rgba(0,0,0,.1)'
            borderRadius='8px'
            display='flex'
            alignItems='center'
            justifyContent='center'
            width='48px'
            height='56px'
            sx={{ 
                margin: "4px",
                backgroundColor: value ? '#fff' : '#eee', 
                border: isEditing ? '1px solid #1A1A1A' : '1px solid #e2e2e2' 
            }}
        >
            <Box>
                {animated ? (
                    <AnimatedDigitLabel>{value}</AnimatedDigitLabel>
                ) : (
                    <DigitLabel>{value}</DigitLabel>
                )}
            </Box>
        </Box>
    );
}