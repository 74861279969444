import { Box, TextField,InputAdornment, IconButton} from '@mui/material';
import { TitleLabel } from '../../../components/Label';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
export default function TextInput({
  inputLabel,
  placeholder,
  id,
  background,
  type,
  value,
  setState,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Box mt="16px">
      <TitleLabel fontSize="15px">{inputLabel}</TitleLabel>
      <TextField
        autoComplete="off"
        variant="filled"
        fullWidth
        id={id}
        type={type === 'password' && !showPassword ? 'password' : 'text'}
        placeholder={placeholder}
        InputProps={{
          disableUnderline: true,
          style: {
            marginTop: '12px',
            borderRadius: '8px',
            height: '48px',
            fontSize: '15px',
            padding: '8px 16px',
            background: background,
          },
          endAdornment: type === 'password' ? (
            <InputAdornment position="end">
              <IconButton onClick={handleToggleShowPassword}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ) : null
        }}
        inputProps={{
          style: { padding: '8px 16px', fontSize: '15px' },
        }}
        value={value}
        onChange={(e) => setState(e.target.value)}
      />
    </Box>
  );
}