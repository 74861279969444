import React, { useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/system";
import { TitleLabel, SubTitleLabel } from "../../components/Label";
import NavigationBar from "../../components/NavigationBar";
import { navigationBarHeight } from "../../components/Layout";
import { Box, TextField } from "@mui/material";
import BottomPanel from "../../components/BottomPanel";
import Panel from "../../components/Panel";
import { UserAPI } from "../../apis/UserAPI";
import Dialog from "../../components/Dialog";
import validator from "./validator";
import StyledButton from "../../components/StyledButton";

const InputBox = styled(Box)(({ theme }) => ({
    backgroundColor: "#FAFAFA",
    width: "100%",
    height: "56px",
    borderRadius: "9px",
}));

export default function ChangeCitizenDigitalPage() {
    const { state } = useLocation();
    const [errors] = useState({});
    const billInfo = state?.billInfo;
    const [disableButton, setDisableButton] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const carrierIdRef = useRef(null);
    const navigate = useNavigate();
    const alertTitle = useRef(null);
    const alertMessage = useRef(null);
    const alertActionLabel = useRef(null);

    const handleBack = () => {
        navigate(-1);
    };

    const sxContainer = {
        pt: `${navigationBarHeight}px`,
    };

    const sxTextField = {
        "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "black",
        },
    };

    const txtInputProps = {
        disableUnderline: true,
        style: {
            fontSize: "15px",
        },
    };

    async function handleUpdateCitizenDigitalCertificate() {
        let payload = {};
        let alert = null;
        if (carrierIdRef.current !== billInfo.citizenDigitalCertificate) {
            if (!validator.isCitizenDigital(carrierIdRef.current, errors)) {
                alert = errors.citizenDigital;
            }
            payload["citizen_digital_certificate"] = carrierIdRef.current;
        } else {
            navigate(-1);
            return;
        }
        if (alert) {
            alertTitle.current = "載具更新失敗";
            alertMessage.current = alert;
            alertActionLabel.current = "確定";
            setShowAlert(true);
        } else {
            const userData = await UserAPI.updateBillInfo(payload);
            if (userData !== null) {
                navigate(-1);
            } else {
                alertTitle.current = "自然人憑證條碼載具更新失敗";
                alertMessage.current = "請確認自然人憑證條碼載具是否正確";
                alertActionLabel.current = "確定";
                setShowAlert(true);
            }
        }
    }

    const bottomPanel = () => {
        return (
            <BottomPanel>
                <StyledButton
                    variant='contained'
                    fullWidth
                    disableElevation
                    onClick={handleUpdateCitizenDigitalCertificate}
                    disabled={disableButton}
                >
                    儲存
                </StyledButton>
            </BottomPanel>
        );
    };

    const navigation = () => {
        return (
            <NavigationBar
                title='自然人憑證條碼載具設定'
                leftButtonHandler={handleBack}
            />
        );
    };

    const handleCarrierIdInput = (value) => {
        carrierIdRef.current = value;
        if (
            carrierIdRef.current.length === 16 ||
            carrierIdRef.current.length === 0
        ) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    };

    const handleAlertAction = () => {
        setShowAlert(false);
    };

    return (
        <>
            <Box sx={sxContainer}>
                {navigation() /* navigation bar */}
                <Box margin='16px'>
                    <Panel>
                        <Box margin='16px'>
                            <TitleLabel
                                fontSize='18px'
                                fontWeight='bold'
                            >
                                自然人憑證條碼載具
                            </TitleLabel>

                            <InputBox
                                display='flex'
                                alignItems='center'
                                sx={{
                                    padding: "16px",
                                    marginTop: "8px",
                                    border: 1,
                                    borderColor: "#C1C1C1",
                                }}
                            >
                                <TextField
                                    variant='standard'
                                    InputProps={txtInputProps}
                                    hiddenLabel
                                    autoComplete='off'
                                    fullWidth
                                    inputProps={{
                                        min: 0,
                                        style: { textAlign: "left" },
                                    }}
                                    placeholder='請輸入卡片右下方16碼大寫英數字'
                                    defaultValue={
                                        billInfo.citizenDigitalCertificate
                                    }
                                    sx={sxTextField}
                                    onChange={(e) => {
                                        handleCarrierIdInput(e.target.value);
                                    }}
                                />
                            </InputBox>
                        </Box>
                    </Panel>
                </Box>
                {bottomPanel()}
            </Box>
            <Dialog
                showDialog={showAlert}
                setShowDialog={setShowAlert}
                title={alertTitle.current}
                message={alertMessage.current}
                actionLabel={alertActionLabel.current}
                actionHandler={handleAlertAction}
            />
        </>
    );
}
