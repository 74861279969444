import { Typography } from "@mui/material";
import { styled } from "@mui/system";

const DescriptionLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.main,
    fontSize: "15px",
    fontWeight: "medium",
    textAlign: "center",
}));

export default DescriptionLabel;
